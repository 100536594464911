<template>
  <SubPageWrap title="How it works">
    <div class="section-wrap download-wrap">
      <div class="content-squeeze">
        <div class="column">
          <div class="content-block">
            <Number :number="1" />
            <p class="title text-color-2">
              Download the Storyline app.
            </p>
            <p class="paragraph text-gray-2">
              Our beautifully designed, simple-to-use app allows you to post
              your loved one’s obituary and service details free of charge. For
              a small, one-time upgrade fee, your funeral director or mortuary
              can activate a Storyline profile for your deceased loved one.
            </p>
            <p class="paragraph text-gray-2">
              With the app upgrade, you can upload pictures, write a life
              sketch, post memories, store obituaries, and even share a link
              that allows others to add pictures, submit stories, and leave
              condolences. You can invite an unlimited number of admins to
              contribute to the profile, edit information, and approve posts.
            </p>
            <AppLinks :hasShadow="true" />
          </div>
        </div>
        <div class="column">
          <div class="image-wrap">
            <img class="img" src="/img/howitworks/phone-1.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="section-wrap plaque-wrap bg-haze">
      <div class="content-squeeze">
        <div class="column">
          <div class="image-wrap">
            <img class="img" src="/img/howitworks/phone-2.png" />
          </div>
        </div>
        <div class="column">
          <div class="content-block">
            <Number :number="2" />
            <p class="title text-color-2">
              <span>Optional</span> Get a Storyline Plaque.
            </p>
            <p class="paragraph text-gray-2">
              Designed to last for generations, Storyline Plaques are
              inconspicuous yet easy-to-find adhesives that work like QR codes –
              scanning a code opens your loved one’s profile on a web page. You
              choose if you’d like the profile to be private, unlisted, or
              public.
            </p>
            <p class="paragraph text-gray-2">
              Available through your funeral director or mortuary, Storyline
              Plaque Application Kits come with materials and instructions for
              applying the code to a headstone or other memorial. Kits can be
              sent directly to you or to the mortuary or headstone manufacturer.
            </p>
            <p class="paragraph text-gray-2">
              Note: If your mortuary doesn’t yet offer Storyline Plaques, refer
              them to our “<router-link
                class="link-color-2"
                to="/for-mortuaries"
                >For Mortuaries</router-link
              >” page where we can provide all the information they need!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-wrap share-wrap">
      <div class="content-squeeze">
        <div class="column">
          <div class="content-block">
            <Number :number="3" />
            <p class="title text-color-2">
              Share Their Story.
            </p>
            <p class="paragraph text-gray-2">
              When they were born and when they passed are only two moments in a
              lifetime, yet they’re often the only details their monument
              preserves. In addition, an obituary in the newspaper is soon
              forgotten. With Storyline, others can discover memories, stories,
              comments, photos, and more for years to come.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="image-wrap">
            <img
              class="img"
              style="max-width: 690px;"
              src="/img/howitworks/phone-3.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="kit-wrap">
      <img class="bg" src="/img/howitworks/plaque-kit-bg.jpg" />
      <div class="content-block">
        <p class="title text-color-1">
          Request a Storyline Plaque Application Kit
        </p>
        <p class="paragraph text-white">
          Our Scannable Plaques can be purchased from participating mortuaries
          and headstone manufacturers. If your mortuary does not yet offer
          Storyline Plaques, please fill out a request with their information. We’ll take it from there.
        </p>
        
        <router-link class="btn btn-gradient" to="/join-platform">Start My Request</router-link>
      </div>
    </div>
  </SubPageWrap>
</template>

<script>
import SubPageWrap from '@/components/layout/SubPageWrap.vue'
import AppLinks from '@/components/AppLinks.vue'
import Number from '@/components/Number.vue'
export default {
  metaInfo: {
    title: 'How It Works'
  },
  components: {
    SubPageWrap,
    AppLinks,
    Number
  }
}
</script>

<style lang="scss" scoped>
.section-wrap {
  padding: 100px 0;
  @media screen and (max-width: 650px) {
    padding: 50px 0;
  }
  &.bg-haze {
    background: #f5f5f5;
  }
  .content-squeeze {
    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
  }
  .column {
    width: 50%;
    @media screen and (max-width: 650px) {
      width: 100%;
    }
  }
  .content-block {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    @media screen and (max-width: 650px) {
      text-align: center;
    }
  }
  .image-wrap {
    .img {
      display: block;
      width: 100%;
    }
  }
}
.download-wrap {
  padding-top: 0;
  @media screen and (max-width: 991px) {
    padding-top: 100px;
  }
  @media screen and (max-width: 650px) {
    padding-top: 50px;
  }
  .image-wrap {
    transform: translate(10px, -180px);
    @media screen and (max-width: 991px) {
      transform: translate(0, 0);
    }
    .img {
      margin: 0 auto;
      max-width: 395px;
      @media screen and (max-width: 650px) {
        margin: 50px auto 0;
      }
    }
  }
}
.plaque-wrap {
  .content-block {
    .title {
      align-items: center;
      display: flex;
      @media screen and (max-width: 650px) {
        justify-content: center;
      }
      @media screen and (max-width: 450px) {
        flex-direction: column;
      }
      span {
        align-self: center;
        display: inline-block;
        border: solid 1px var(--color-1);
        font-family: 'sans-bold';
        font-size: 2.4rem;
        line-height: 1;
        margin-right: 10px;
        padding: 6px 28px;
        transform: translateY(3px);
        @media screen and (max-width: 450px) {
          margin-bottom: 10px;
          margin-right: 0;
        }
      }
    }
  }
  .image-wrap {
    padding-right: 50px;
    @media screen and (max-width: 650px) {
      padding-right: 0;
    }
    .img {
      max-width: 760px;
      @media screen and (max-width: 650px) {
        margin: 0 auto 50px;
      }
    }
  }
}
.share-wrap {
  .content-block {
    max-width: 475px;
    @media screen and (max-width: 650px) {
      max-width: 100%;
    }
  }
  .image-wrap {
    .img {
      @media screen and (max-width: 650px) {
        margin: 50px auto 0;
      }
    }
  }
}
.kit-wrap {
  position: relative;
  text-align: center;
  .bg {
    display: block;
    width: 100%;
    @media screen and (max-width: 1024px) {
      height: 500px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
    }
  }
  .content-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 650px) {
      padding: 0 20px;
      width: 100%;
    }
  }
}
</style>
