<template>
  <SubPageWrap title="Frequently Asked">
    <div class="section-wrap faq-wrap">
      <div class="content-squeeze">
        <div class="question-wrap">
          <p class="question" @click="toggleFAQ">
            <span class="q">Q</span>
            My mortuary/headstone manufacturer doesn’t yet offer
            Storyline. How can I get set up?
          </p>
          <div class="answer-wrap">
            <p class="answer">
              Easy! Simply fill out this <router-link
                class="link-color-2"
                to="/join-platform"
                >form</router-link> with the mortuary's information and we will reach out to them. The mortuary will collect a
              one-time fee to activate your loved one’s profile in the
              Storyline app. Additionally, you can request the Storyline Plaque
              Application Kit with materials and instructions for affixing the
              scannable code.
            </p>
          </div>
        </div>
        <div class="question-wrap">
          <p class="question" @click="toggleFAQ">
            <span class="q">Q</span>
            How do I set up a Storyline profile?
          </p>
          <div class="answer-wrap">
            <p class="answer">
              When your mortuary requests a kit, they will use your email
              address to activate the Storyline profile. You will receive a
              welcome email with instructions for downloading the Storyline app,
              setting up your loved one’s profile, editing information, and
              more.
            </p>
          </div>
        </div>
        <div class="question-wrap">
          <p class="question" @click="toggleFAQ">
            <span class="q">Q</span>
            Do I have to pay to keep my loved one’s profile active?
          </p>
          <div class="answer-wrap">
            <p class="answer">
              Storyline simply requires a one-time fee for the code and to
              activate the profile. No subscriptions. No maintenance costs. Just
              pay once and enjoy telling their story forevermore!
            </p>
          </div>
        </div>
        <div class="question-wrap">
          <p class="question" @click="toggleFAQ">
            <span class="q">Q</span>
            How much control do I have over the information posted about my
            loved one? What if I want to remove comments or edit details?
          </p>
          <div class="answer-wrap">
            <p class="answer">
              The person who activates a Storyline profile can select an
              unlimited number of admins who have the permissions to edit a
              profile. They can also approve or deny messages of condolences,
              comments, etc.
            </p>
          </div>
        </div>
        <div class="question-wrap">
          <p class="question" @click="toggleFAQ">
            <span class="q">Q</span>
            How is the code affixed to a headstone or other memorial? How long
            will it last?
          </p>
          <div class="answer-wrap">
            <p class="answer">
              The Storyline Plaque is an industrial-strength adhesive designed
              to hold for generations to come – no matter the climate. You can
              request the Storyline Plaque Application Kit be sent directly to
              you or to the mortuary/headstone manufacturer. Instructions for
              affixing the code are included.
            </p>
          </div>
        </div>
      </div>
    </div>
  </SubPageWrap>
</template>

<script>
import SubPageWrap from '@/components/layout/SubPageWrap.vue'
export default {
  metaInfo: {
    title: 'Frequently Asked Questions'
  },
  components: {
    SubPageWrap
  },
  methods: {
    toggleFAQ() {
      const target = event.target
      const parent = target.parentElement
      const answerWrap = target.nextSibling
      const answer = target.nextSibling.firstChild
      const answerHeight = answer.offsetHeight
      if (parent.classList.contains('is-active')) {
        parent.classList.remove('is-active')
        answerWrap.style.height = 0
      } else {
        parent.classList.add('is-active')
        answerWrap.style.height = `${answerHeight}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-wrap {
  padding: 100px 0;
  @media screen and (max-width: 767px) {
    padding: 50px 0;
  }
  .content-squeeze {
    flex-direction: column;
  }
}
.question-wrap {
  margin-top: 50px;
  overflow: hidden;
  @media screen and (max-width: 650px) {
    margin-top: 25px;
  }
  &.is-active {
    .question {
      &:after {
        transform: rotate(0deg);
      }
    }
    .answer-wrap {
      overflow: hidden;
    }
  }
  &:first-child {
    margin-top: 0;
  }
  .question {
    background: #162740;
    color: var(--white);
    cursor: pointer;
    display: flex;
    font-family: 'sans-light';
    font-size: 4.4rem;
    line-height: 1.44;
    padding: 30px 175px 30px 50px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 1024px) {
      font-size: 3.6rem;
      padding: 30px 100px 30px 30px;
    }
    @media screen and (max-width: 767px) {
      font-size: 2.4rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 2rem;
      padding: 20px 50px 20px 20px;
    }
    &:before,
    &:after {
      background: var(--white);
      content: '';
      height: 2px;
      position: absolute;
      right: 50px;
      top: 50%;
      width: 36px;
      @media screen and (max-width: 1024px) {
        right: 30px;
      }
      @media screen and (max-width: 767px) {
        width: 24px;
      }
      @media screen and (max-width: 650px) {
        right: 15px;
        top: 40px;
      }
    }
    &:after {
      transform: rotate(90deg);
      transform-origin: center center;
      transition: transform 250ms;
    }
    .q {
      color: var(--color-1);
      display: block;
      font-size: 5.5rem;
      margin-right: 50px;
      transform: translateY(-5px);
      @media screen and (max-width: 767px) {
        font-size: 4rem;
        margin-right: 30px;
      }
      @media screen and (max-width: 650px) {
        font-size: 3.5rem;
        margin-right: 20px;
      }
    }
  }
  .answer-wrap {
    border: solid 1px #c0cad8;
    border-top: none;
    height: 0;
    position: relative;
    transition: height 500ms;
  }
  .answer {
    font-family: 'sans-light';
    font-size: 2.4rem;
    left: 0;
    line-height: 1.875;
    padding: 30px 50px;
    position: absolute;
    top: 0;
    @media screen and (max-width: 991px) {
      font-size: 1.8rem;
      padding: 20px 35px;
    }
  }
}
</style>
