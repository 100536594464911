<template>
  <div class="header-wrap">
    <a href="/">
      <svg
        class="logo"
        xmlns="http://www.w3.org/2000/svg"
        width="191.844"
        height="46.687"
        viewBox="0 0 191.844 46.687"
      >
        <g
          id="Group_1"
          data-name="Group 1"
          transform="translate(-91.914 -328.001)"
        >
          <path
            id="Path_63"
            data-name="Path 63"
            d="M148.246,312.925c0-1.7.852-2.555,2.076-2.555a1.913,1.913,0,0,1,2.024,1.756c0,3.94,3.3,5.537,6.175,5.537,3.195,0,4.9-1.544,4.9-4.259,0-3.141-3.355-4.1-6.6-5.111-3.567-1.118-7.879-2.981-7.879-7.4,0-4.1,3.726-6.76,9.158-6.76,4.577,0,8.783,1.917,8.783,5.43a2.153,2.153,0,0,1-2.289,2.4,1.625,1.625,0,0,1-1.81-1.864c0-2.715-2.5-4.685-5.43-4.685s-4.259,1.384-4.259,3.78c0,3.194,2.4,4.153,5.643,5.217,3.833,1.224,9.05,2.662,9.05,7.453,0,4.206-4.472,7.08-10.488,7.08C152.824,318.941,148.246,317.025,148.246,312.925Z"
            transform="translate(-56.332 44.994)"
            fill="#50badd"
          />
          <path
            id="Path_64"
            data-name="Path 64"
            d="M174.335,311.383V296.8c0-.852-.426-1.118-1.171-1.118h-2.236v-1.012c3.513-.639,5.484-2.289,6.228-5.963h1.438v4.153c0,1.065.373,1.384,1.065,1.384h4.792v1.439h-4.792c-.692,0-1.065.319-1.065,1.118v15.226c0,2.662.745,4.792,3.142,4.792a4.389,4.389,0,0,0,2.715-.8l.586,1.012a6.83,6.83,0,0,1-4.579,1.491C176.838,318.518,174.335,316.654,174.335,311.383Z"
            transform="translate(-57.882 45.365)"
            fill="#50badd"
          />
          <path
            id="Path_65"
            data-name="Path 65"
            d="M188.067,306.591a12.182,12.182,0,0,1,12.512-12.458c6.548,0,12.35,4.631,12.35,12.3a12.2,12.2,0,0,1-12.511,12.511A12.024,12.024,0,0,1,188.067,306.591Zm20.39,1.65c0-6.867-3.833-12.671-9.157-12.671-3.833,0-6.76,2.928-6.76,9.264,0,6.867,3.885,12.671,9.157,12.671C205.583,317.5,208.457,314.523,208.457,308.241Z"
            transform="translate(-59.053 44.994)"
            fill="#50badd"
          />
          <path
            id="Path_66"
            data-name="Path 66"
            d="M217.087,317.557c2.5-.319,3.408-.9,3.408-2.874V299.616c0-2.342-.48-3.354-3.461-3.034V295.57l5.857-1.33c1.17-.266,1.862.266,1.862,1.6,0,2.024.374,2.5,1.118,1.757s3.195-3.46,7.029-3.46c4.152,0,4.9,2.821,4.9,4.259A2.451,2.451,0,0,1,235.348,301a2.029,2.029,0,0,1-2.236-1.863,2.5,2.5,0,0,0-2.715-2.556c-3.035,0-5.644,3.141-5.644,8.145v9.956c0,1.97.906,2.556,3.567,2.874v1.066H217.087Z"
            transform="translate(-61.031 44.994)"
            fill="#50badd"
          />
          <path
            id="Path_67"
            data-name="Path 67"
            d="M273.8,314.075V288.309c0-2.342-.479-3.3-3.46-3.035v-1.012l5.857-1.277c1.17-.266,1.862.266,1.862,1.491v29.493c0,1.81.9,2.556,3.461,1.81V316.9a7.067,7.067,0,0,1-3.993,1.225C275.185,318.123,273.8,317.164,273.8,314.075Z"
            transform="translate(-64.673 45.761)"
            fill="#50badd"
          />
          <path
            id="Path_68"
            data-name="Path 68"
            d="M319.655,315.268v-11.34c0-4.1-2.024-7.134-6.016-7.134-3.834,0-6.443,3.195-6.443,7.134v10.754c0,1.97.906,2.556,3.461,2.874v1.066H299.531v-1.066c2.5-.319,3.407-.9,3.407-2.874V299.616c0-2.342-.48-3.354-3.461-3.034V295.57l5.857-1.33c1.171-.266,1.862.266,1.862,1.6,0,2.024.48,2.716,1.225,1.97a9.983,9.983,0,0,1,7.72-3.673c3.992,0,7.772,2.448,7.772,8.677v11.98c0,1.862.9,2.555,2.663,2.129v1.012a6.544,6.544,0,0,1-3.407.959C321.038,318.889,319.655,317.93,319.655,315.268Z"
            transform="translate(-66.663 44.994)"
            fill="#50badd"
          />
          <path
            id="Path_69"
            data-name="Path 69"
            d="M352.544,313.724a11.184,11.184,0,0,1-9.689,5.217,11.923,11.923,0,0,1-12.3-12.35c0-7.932,6.016-12.458,12.458-12.458,5.644,0,9.423,3.46,9.423,7.826,0,3.353-2.874,4.258-9.689,4.418l-6.229.106c-1.065,0-1.277.692-1.118,1.971a9.856,9.856,0,0,0,9.8,7.56,7.778,7.778,0,0,0,6.442-2.981Zm-17.515-10.7c-.107,1.917.479,2.236,1.6,2.236l4.684-.106c5.111-.053,6.762-1.277,6.762-3.728,0-3.513-2.077-6.015-5.91-6.015C337.9,295.41,335.455,298.339,335.029,303.023Z"
            transform="translate(-68.786 44.994)"
            fill="#50badd"
          />
          <path
            id="Path_70"
            data-name="Path 70"
            d="M292.346,314.832v-18.39c0-.042-.007-.076-.008-.116a16.764,16.764,0,0,1-3.391,4.051,3.213,3.213,0,0,0-.859,1.343v13.112c0,1.97-.906,2.556-3.407,2.874v1.066h11.126v-1.066C293.251,317.388,292.346,316.8,292.346,314.832Z"
            transform="translate(-65.652 44.845)"
            fill="#50badd"
          />
          <path
            id="Path_71"
            data-name="Path 71"
            d="M289.255,284.316c0-.02-.008-.039-.013-.06-.014-.048-.02-.1-.034-.145l-.015,0a2.76,2.76,0,0,0-2.561-1.926,2.7,2.7,0,0,0-2.681,2.614,2.735,2.735,0,0,0,2.675,2.674,2.511,2.511,0,0,0,1.092-.265,10.363,10.363,0,0,1-1.485,4.862,26.641,26.641,0,0,1-5.386,6.257,62.463,62.463,0,0,1-14.374,9.026,155.822,155.822,0,0,1-15.919,6.458l-2.037.7a16.838,16.838,0,0,0-2.084.825,17.037,17.037,0,0,0-3.86,2.391,8.72,8.72,0,0,0-2.744,3.892,7,7,0,0,0-.337,2.459,6.456,6.456,0,0,0,.578,2.5l.04-.022a2.715,2.715,0,0,0,2.5,1.893,2.864,2.864,0,0,0,2.744-2.731,2.729,2.729,0,0,0-2.644-2.7,2.628,2.628,0,0,0-1.254.327,4.931,4.931,0,0,1,.17-1.2A6.9,6.9,0,0,1,243.7,319a14.211,14.211,0,0,1,3.406-2.237,15.639,15.639,0,0,1,1.909-.807l2.031-.752c5.4-2.054,10.767-4.2,15.965-6.733a68.233,68.233,0,0,0,14.726-9.134,28.148,28.148,0,0,0,5.871-6.468,13.493,13.493,0,0,0,1.757-4.133A9.155,9.155,0,0,0,289.255,284.316Z"
            transform="translate(-62.565 45.811)"
            fill="#50badd"
          />
          <path
            id="Path_72"
            data-name="Path 72"
            d="M258.1,294.646v1.066c3.088.852,4.045,1.491.479,10.488l-2.716,6.974c-.319.639-.8.639-1.117,0l-5.111-12.512c-1.437-3.459-1.224-4.1,1.7-4.95v-1.066h-10.6v1.066c2.289.852,2.875,1.491,4.26,4.95l6.389,15.705a8.743,8.743,0,0,1,.213,6.868c-.2.483-.429.954-.666,1.417a21.8,21.8,0,0,1-1.857,3.024c-.273.368-.56.726-.865,1.068-.153.171-.31.338-.472.5-.076.076-.429.372-.392.467.147.089.784-.2.912-.264a6.8,6.8,0,0,0,1.227-.758,8.207,8.207,0,0,0,2.132-2.5,19.334,19.334,0,0,0,1.738-3.434l8.943-22.095c1.332-3.353,2.183-4.1,5.218-4.95v-1.066Z"
            transform="translate(-62.651 44.959)"
            fill="#50badd"
          />
        </g>
      </svg>
    </a>
    <div class="nav-wrap">
      <div class="left">
        <router-link class="link" to="/how-it-works">How It Works</router-link>
        <router-link class="link" to="/faqs">FAQs</router-link>
        <router-link class="link" to="/contact-us">Contact Us</router-link>
      </div>
      <!-- <div class="right">
        <router-link class="link" to="/">Sign In</router-link>
        <router-link class="link btn" to="/">Sign Up</router-link>
      </div> -->
    </div>
    <div class="nav-button" @click="toggleNav">
      <span class="bar bar-top"></span>
      <span class="bar bar-middle"></span>
      <span class="bar bar-bottom"></span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toggleNav() {
      const navButton = document.querySelector('.nav-button')
      const nav = document.querySelector('.nav-wrap')
      navButton.classList.toggle('is-active')
      nav.classList.toggle('is-active')
    }
  }
}
</script>

<style lang="scss" scoped>
.header-wrap {
  align-items: center;
  display: flex;
  height: 120px;
  left: 0;
  padding: 0 50px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  @media screen and (max-width: 767px) {
    height: 60px;
    padding: 0 20px;
  }
  .logo {
    margin-right: 40px;
    @media screen and (max-width: 767px) {
      height: 24px;
      transform: translateY(4px);
      width: 100px;
    }
  }
}
.nav-wrap {
  display: flex;
  flex: 1;
  @media screen and (max-width: 650px) {
    background: #f5f5f5;
    border-left: solid 2px var(--color-2);
    height: 100%;
    opacity: 0;
    padding: 50px 20px 20px;
    position: fixed;
    right: 0;
    transition: transform 500ms, opacity 1ms 500ms;
    transform: translateX(100%);
    top: 0;
    width: 300px;
  }
  &.is-active {
    @media screen and (max-width: 650px) {
      opacity: 1;
      transition: transform 500ms;
      transform: translateX(0);
    }
  }
  .link {
    font-family: 'sans-bold';
    font-size: 2rem;
    text-decoration: none;
    @media screen and (max-width: 767px) {
      font-size: 1.6rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 2rem;
    }
    &:link,
    &:visited {
      color: var(--white);
      @media screen and (max-width: 650px) {
        color: var(--color-2);
      }
    }
    &:hover,
    &:active,
    &.is-active {
      color: var(--color-1);
    }
    &.btn {
      background: var(--white);
      border-radius: 1.5em;
      color: var(--color-2);
      padding: 0.5em 1.35em;
      text-align: center;
    }
  }
  .left {
    align-items: center;
    display: flex;
    @media screen and (max-width: 650px) {
      align-items: flex-start;
      flex-direction: column;
    }
    .link {
      margin-right: 20px;
      @media screen and (max-width: 650px) {
        margin: 0 0 20px;
      }
      &:last-child {
        margin-right: 0;
        @media screen and (max-width: 650px) {
          margin-bottom: 0;
        }
      }
    }
  }
  .right {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    .link {
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.nav-button {
  cursor: pointer;
  display: none;
  height: 18px;
  position: absolute;
  right: 20px;
  width: 30px;
  @media screen and (max-width: 650px) {
    display: block;
  }
  &.is-active {
    .bar {
      background: var(--color-2);
      &.bar-top {
        top: 3px;
        transform: rotate(36deg);
        width: 16px;
      }
      &.bar-bottom {
        bottom: 3px;
        transform: rotate(-36deg);
        width: 16px;
      }
    }
  }
  .bar {
    border-radius: 1px;
    background: var(--white);
    display: block;
    height: 2px;
    position: absolute;
    right: 0;
    transition: transform 500ms;
    width: 30px;
    &.bar-top {
      top: 0;
    }
    &.bar-middle {
      top: 50%;
      transform: translateY(-50%);
    }
    &.bar-bottom {
      bottom: 0;
    }
  }
}
</style>
