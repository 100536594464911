<template>
  <div class="number-wrap">
    <svg
      class="circle"
      height="209.479"
      viewBox="0 0 209.479 209.479"
      width="209.479"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m72.562 0a72.563 72.563 0 0 1 3.615 145.036c-.9.051-2.252.088-3.615.088a72.562 72.562 0 0 1 -72.562-72.562"
        fill="none"
        stroke="#50badd"
        stroke-linecap="round"
        stroke-width="3"
        transform="matrix(-.70710678 -.70710678 .70710678 -.70710678 104.74 207.358)"
      />
    </svg>
    <span class="number">{{ number }}</span>
  </div>
</template>

<script>
export default {
  props: {
    number: Number
  }
}
</script>

<style lang="scss" scoped>
.number-wrap {
  height: 150px;
  overflow: hidden;
  position: relative;
  width: 150px;
  @media screen and (max-width: 1439px) {
    height: 125px;
    width: 125px;
  }
  @media screen and (max-width: 991px) {
    height: 100px;
    width: 100px;
  }
  @media screen and (max-width: 650px) {
    margin: 0 auto;
  }
  .circle {
    height: auto;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 140%;
  }
  .number {
    color: var(--color-2);
    font-size: 8rem;
    left: 50%;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -80%);
    @media screen and (max-width: 991px) {
      font-size: 5rem;
    }
  }
}
</style>
