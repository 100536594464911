import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HowItWorks from '../views/HowItWorks.vue'
import FAQs from '../views/FAQs.vue'
import ContactUs from '../views/ContactUs.vue'
import ForMortuaries from '../views/ForMortuaries.vue'
import JoinPlatform from '../views/JoinPlatform.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/how-it-works',
    name: 'How It Works',
    component: HowItWorks
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: FAQs
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: ContactUs
  },
  {
    path: '/for-mortuaries',
    name: 'For Mortuaries',
    component: ForMortuaries
  },
  {
    path: '/join-platform',
    name: 'Join Platform',
    component: JoinPlatform
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  const navButton = document.querySelector('.nav-button')
  const nav = document.querySelector('.nav-wrap')
  if (navButton && navButton.classList.contains('is-active')) {
    navButton.classList.remove('is-active')
    nav.classList.remove('is-active')
  }
  next()
})

export default router
