<template>
  <div class="home">
    <div class="hero-wrap">
      <img class="hero-bg" src="/img/home/hero-bg.jpg" alt="A daughter and her mother creating memories that will be preserved on Storyline" />
      <div class="hero-content">
        <div class="content-squeeze">
          <div class="column">
            <div class="content-block">
              <p class="title text-white">
                Because your loved one's memory is much more than a dash between
                two dates.
              </p>
              <p class="subtitle text-white">
                Tell Their Story with Storyline
              </p>
              <AppLinks />
            </div>
          </div>
          <div class="column">
            <div class="story-card-wrap">
              <img class="avatar" src="/img/home/maryann.png" alt="Profile image of MaryAnn Thompson"/>
              <h6 class="name">MaryAnn Ruth Thompson</h6>
              <p class="years">1945 - 2020</p>
              <p class="locations">
                Born: San Francisco, CA<br />Died: Santa Fe, NM
              </p>
              <a href="https://app.storyline.co/storyline/sl-f8be24fcbd6013044918fc4" class="btn btn-gradient" target="_blank">View her Storyline</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="download-wrap">
      <div class="content-squeeze">
        <div class="column">
          <div class="content-block">
            <p class="title text-color-2">
              Long or short, complicated or simple, your loved one’s life is a story worth telling.
            </p>
            <p class="paragraph text-gray-2">
              Storyline is a simple way to tell your deceased loved one’s story
              for generations to come. With a beautiful, easy-to-use app, you
              can preserve and share stories, photos, memories, and more.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="img-wrap">
            <img class="phone" src="/img/home/phone-download-v3.png" alt="A preview of a Storyline" />
            <img class="collage" src="/img/home/photos-download.png" alt="An up close look at a memory" />
          </div>
        </div>
      </div>
    </div>
    <div class="invite-wrap">
      <div class="content-squeeze">
        <div class="one-third">
          <div class="content-block">
            <p class="title text-white">
              Invite friends and family to add their memories with photos.
            </p>
            <p class="paragraph text-white">
              Storyline is the only way to collect all of the stories, photos
              of your loved ones from everyone who knew them. It
              becomes an interactive book of their life to remember them for
              decades to come.
            </p>
          </div>
        </div>
        <div class="two-thirds">
          <div class="phone-wrap">
            <img class="phone" src="/img/home/phone-invite-v3.png" alt="Another view of the Storyline main memory feed" />
          </div>
          <div class="collage-wrap">
            <img class="collage" src="/img/home/photos-invite.png" alt="An up close view of the Storyline memory feed"/>
          </div>
        </div>
      </div>
    </div>
    <Free />
  </div>
</template>

<script>
import AppLinks from '@/components/AppLinks.vue'
import Free from '@/components/Free.vue'
export default {
  metaInfo: {
    title: 'Tell Their Story | Storyline'
  },
  components: {
    AppLinks,
    Free
  }
}
</script>

<style lang="scss" scoped>
.hero-wrap {
  position: relative;
  .hero-bg {
    display: block;
    width: 100%;
    @media screen and (max-width: 1439px) {
      height: 850px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
    }
    @media screen and (max-width: 650px) {
      height: 750px;
    }
  }
}
.hero-content {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  .content-squeeze {
    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
  }
  .column {
    width: calc(100% / 2);
    @media screen and (max-width: 1200px) {
      width: calc(100% / 2);
    }
    @media screen and (max-width: 650px) {
      width: 100%;
    }
    @media screen and (min-width: 1200px) {
      width: 40%;
    }
  }
  .content-block {
    padding-right: 30px;
    @media screen and (max-width: 650px) {
      padding-right: 0;
      text-align: center;
    }
  }
}
.story-card-wrap {
  background: var(--white);
  border-radius: 10px;
  margin: 0 auto;
  max-width: 480px;
  padding: 0 20px 20px;
  text-align: center;
  width: 90%;
  @media screen and (max-width: 650px) {
    margin-top: 100px;
    max-width: 280px;
  }
  .avatar {
    margin-top: -99px;
    @media screen and (max-width: 991px) {
      margin-top: -80px;
      width: 150px;
    }
    @media screen and (max-width: 767px) {
      margin-top: -60px;
      width: 120px;
    }
    @media screen and (max-width: 650px) {
      margin-top: -50px;
      width: 100px;
    }
  }
  .name {
    color: var(--color-2);
    font-family: 'sans-bold';
    font-size: 2.5rem;
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
  }
  .years {
    color: var(--gray-1);
    font-family: 'sans-bold';
    font-size: 2.2rem;
    margin-top: 20px;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
  .locations {
    color: var(--gray-1);
    font-size: 2.2rem;
    margin-top: 20px;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
  .btn {
    display: block;
    font-size: 2.2rem;
    margin-top: 25px;
    width: 100%;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
}
.download-wrap {
  padding: 100px 0;
  .content-squeeze {
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .column {
    display: flex;
    width: calc(100% / 3);
    @media screen and (max-width: 1439px) {
      width: calc(100% / 2);
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .content-block {
    align-self: center;
    @media screen and (max-width: 1439px) {
      padding-right: 75px;
    }
    @media screen and (max-width: 768px) {
      padding-right: 0;
      text-align: center;
    }
  }
  .img-wrap {
    padding-left: 75px;
    position: relative;
    @media screen and (max-width: 768px) {
      margin-top: 100px;
      padding-left: 0;
      width: 100%;
    }
    .phone {
      display: block;
      margin: 0;
      max-width: 480px;
      width: 90%;
      @media screen and (max-width: 1439px) {
        margin: 0;
        width: 330px;
      }
      @media screen and (max-width: 1024px) {
        width: 300px;
      }
      @media screen and (max-width: 768px) {
        margin: 0 auto;
      }
    }
    .collage {
      display: block;
      left: 0;
      max-width: 700px;
      position: absolute;
      transform: translate(calc(95px + 10%), -35px);
      top: 50%;
      width: 110%;
      @media screen and (max-width: 1439px) {
        transform: translate(-25px, -38px);
        width: 540px;
      }
      @media screen and (max-width: 1024px) {
        transform: translate(30px, -20px);
        width: 400px;
      }
      @media screen and (max-width: 768px) {
        left: 50%;
        transform: translate(-50%, -20px);
      }
    }
  }
}
.invite-wrap {
  background: linear-gradient(
    0deg,
    rgba(0, 108, 177, 1) 0%,
    rgba(40, 81, 151, 1) 60%
  );
  padding: 100px 0;
  .content-squeeze {
    align-items: center;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  .one-third {
    width: 33%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .two-thirds {
    align-items: center;
    display: flex;
    width: 67%;
    @media screen and (max-width: 1024px) {
      margin-top: 100px;
      width: 100%;
    }
    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
  }
  .content-block {
    @media screen and (max-width: 1024px) {
      text-align: center;
    }
  }
  .phone-wrap {
    padding: 0 35px;
  }
  .phone {
    display: block;
    width: 100%;
    @media screen and (max-width: 650px) {
      max-width: 300px;
    }
  }
  .collage {
    display: block;
    width: 100%;
    @media screen and (max-width: 650px) {
      margin-top: 50px;
    }
  }
}
</style>
