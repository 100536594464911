<template>
  <div id="app" class="app-wrap">
    <HeaderWrap />
    <div class="content-wrap">
      <router-view />
    </div>
    <FooterWrap />
  </div>
</template>

<script>
import HeaderWrap from '@/components/HeaderWrap.vue'
import FooterWrap from '@/components/FooterWrap.vue'

export default {
  components: {
    HeaderWrap,
    FooterWrap
  }
}
</script>

<style lang="scss">
.app-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  .content-wrap {
    flex: 1;
  }
}

.content-squeeze {
  display: flex;
  padding: 0 105px;
  position: relative;
  width: 100%;
  @media screen and (max-width: 991px) {
    padding: 0 50px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
}

.content-block {
  .title {
    font-family: 'sans-light';
    font-size: 4.4rem;
    line-height: 1.25;
    @media screen and (max-width: 1440px) {
      font-size: 3.6rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 3.2rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 2.4rem;
    }
  }
  .subtitle {
    font-family: 'sans-bold';
    font-size: 2.4rem;
    margin-top: 20px;
    @media screen and (max-width: 1440px) {
      font-size: 1.8rem;
    }
  }
  .paragraph {
    line-height: 2em;
    margin-top: 20px;
  }
  .btn {
    font-size: 2.2rem !important;
    margin-top: 40px;
    @media screen and (max-width: 1440px) {
      font-size: 1.8rem !important;
    }
  }
}

/*Forms*/
.input-group {
  .input {
    background: transparent;
    border: none;
    border-bottom: solid 1px var(--gray-2);
    color: var(--gray-2);
    font-size: 1.8rem;
    height: 60px;
    outline: none;
    width: 100%;
    &:focus {
      outline: none;
    }
    &.has-error {
      border-bottom: solid 1px #ff0000 !important;
    }
  }
  .textarea {
    border: none;
    border-bottom: solid 1px var(--gray-2);
    color: var(--gray-2);
    font-family: 'sans-regular';
    font-size: 1.8rem;
    min-height: 200px;
    outline: none;
    width: 100%;
    &:focus {
      outline: none;
    }
    &.has-error {
      border-bottom: solid 1px #ff0000 !important;
    }
  }
}

/*Buttons*/
.btn {
  cursor: pointer;
  border: none;
  border-radius: 2em;
  display: inline-block;
  font-size: 1.6rem;
  line-height: 1;
  padding: 0.7em 1.9em;
  text-align: center;
  text-decoration: none;
  &.btn-white {
    background: var(--white);
    color: var(--color-2);
  }
  &.btn-gradient {
    background: linear-gradient(
      40deg,
      rgba(0, 108, 177, 1) 0%,
      rgba(40, 81, 151, 1) 70%
    );
    color: var(--white);
  }
}
</style>
