<template>
  <div class="subpage-wrap">
    <div class="hero-wrap">
      <div class="content-squeeze">
        <p class="title text-white">{{ title }}</p>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
.subpage-wrap {
  .hero-wrap {
    background: linear-gradient(
      20deg,
      rgba(103, 196, 255, 1) 0%,
      rgba(40, 81, 151, 1) 70%
    );
    padding: 220px 0 50px;
    @media screen and (max-width: 1024px) {
      padding: 150px 0 50px;
    }
    @media screen and (max-width: 650px) {
      padding: 75px 0 25px;
    }
    .title {
      font-size: 7rem;
      font-family: 'sans-light';
      @media screen and (max-width: 991px) {
        font-size: 5rem;
      }
      @media screen and (max-width: 650px) {
        font-size: 4rem;
      }
    }
  }
}
</style>
