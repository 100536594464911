<template>
  <div class="footer-wrap">
    <div class="left">
      <div class="column privacy">
        <svg
          class="logo"
          xmlns="http://www.w3.org/2000/svg"
          width="191.844"
          height="46.687"
          viewBox="0 0 191.844 46.687"
        >
          <g
            id="Group_1"
            data-name="Group 1"
            transform="translate(-91.914 -328.001)"
          >
            <path
              id="Path_63"
              data-name="Path 63"
              d="M148.246,312.925c0-1.7.852-2.555,2.076-2.555a1.913,1.913,0,0,1,2.024,1.756c0,3.94,3.3,5.537,6.175,5.537,3.195,0,4.9-1.544,4.9-4.259,0-3.141-3.355-4.1-6.6-5.111-3.567-1.118-7.879-2.981-7.879-7.4,0-4.1,3.726-6.76,9.158-6.76,4.577,0,8.783,1.917,8.783,5.43a2.153,2.153,0,0,1-2.289,2.4,1.625,1.625,0,0,1-1.81-1.864c0-2.715-2.5-4.685-5.43-4.685s-4.259,1.384-4.259,3.78c0,3.194,2.4,4.153,5.643,5.217,3.833,1.224,9.05,2.662,9.05,7.453,0,4.206-4.472,7.08-10.488,7.08C152.824,318.941,148.246,317.025,148.246,312.925Z"
              transform="translate(-56.332 44.994)"
              fill="#50badd"
            />
            <path
              id="Path_64"
              data-name="Path 64"
              d="M174.335,311.383V296.8c0-.852-.426-1.118-1.171-1.118h-2.236v-1.012c3.513-.639,5.484-2.289,6.228-5.963h1.438v4.153c0,1.065.373,1.384,1.065,1.384h4.792v1.439h-4.792c-.692,0-1.065.319-1.065,1.118v15.226c0,2.662.745,4.792,3.142,4.792a4.389,4.389,0,0,0,2.715-.8l.586,1.012a6.83,6.83,0,0,1-4.579,1.491C176.838,318.518,174.335,316.654,174.335,311.383Z"
              transform="translate(-57.882 45.365)"
              fill="#50badd"
            />
            <path
              id="Path_65"
              data-name="Path 65"
              d="M188.067,306.591a12.182,12.182,0,0,1,12.512-12.458c6.548,0,12.35,4.631,12.35,12.3a12.2,12.2,0,0,1-12.511,12.511A12.024,12.024,0,0,1,188.067,306.591Zm20.39,1.65c0-6.867-3.833-12.671-9.157-12.671-3.833,0-6.76,2.928-6.76,9.264,0,6.867,3.885,12.671,9.157,12.671C205.583,317.5,208.457,314.523,208.457,308.241Z"
              transform="translate(-59.053 44.994)"
              fill="#50badd"
            />
            <path
              id="Path_66"
              data-name="Path 66"
              d="M217.087,317.557c2.5-.319,3.408-.9,3.408-2.874V299.616c0-2.342-.48-3.354-3.461-3.034V295.57l5.857-1.33c1.17-.266,1.862.266,1.862,1.6,0,2.024.374,2.5,1.118,1.757s3.195-3.46,7.029-3.46c4.152,0,4.9,2.821,4.9,4.259A2.451,2.451,0,0,1,235.348,301a2.029,2.029,0,0,1-2.236-1.863,2.5,2.5,0,0,0-2.715-2.556c-3.035,0-5.644,3.141-5.644,8.145v9.956c0,1.97.906,2.556,3.567,2.874v1.066H217.087Z"
              transform="translate(-61.031 44.994)"
              fill="#50badd"
            />
            <path
              id="Path_67"
              data-name="Path 67"
              d="M273.8,314.075V288.309c0-2.342-.479-3.3-3.46-3.035v-1.012l5.857-1.277c1.17-.266,1.862.266,1.862,1.491v29.493c0,1.81.9,2.556,3.461,1.81V316.9a7.067,7.067,0,0,1-3.993,1.225C275.185,318.123,273.8,317.164,273.8,314.075Z"
              transform="translate(-64.673 45.761)"
              fill="#50badd"
            />
            <path
              id="Path_68"
              data-name="Path 68"
              d="M319.655,315.268v-11.34c0-4.1-2.024-7.134-6.016-7.134-3.834,0-6.443,3.195-6.443,7.134v10.754c0,1.97.906,2.556,3.461,2.874v1.066H299.531v-1.066c2.5-.319,3.407-.9,3.407-2.874V299.616c0-2.342-.48-3.354-3.461-3.034V295.57l5.857-1.33c1.171-.266,1.862.266,1.862,1.6,0,2.024.48,2.716,1.225,1.97a9.983,9.983,0,0,1,7.72-3.673c3.992,0,7.772,2.448,7.772,8.677v11.98c0,1.862.9,2.555,2.663,2.129v1.012a6.544,6.544,0,0,1-3.407.959C321.038,318.889,319.655,317.93,319.655,315.268Z"
              transform="translate(-66.663 44.994)"
              fill="#50badd"
            />
            <path
              id="Path_69"
              data-name="Path 69"
              d="M352.544,313.724a11.184,11.184,0,0,1-9.689,5.217,11.923,11.923,0,0,1-12.3-12.35c0-7.932,6.016-12.458,12.458-12.458,5.644,0,9.423,3.46,9.423,7.826,0,3.353-2.874,4.258-9.689,4.418l-6.229.106c-1.065,0-1.277.692-1.118,1.971a9.856,9.856,0,0,0,9.8,7.56,7.778,7.778,0,0,0,6.442-2.981Zm-17.515-10.7c-.107,1.917.479,2.236,1.6,2.236l4.684-.106c5.111-.053,6.762-1.277,6.762-3.728,0-3.513-2.077-6.015-5.91-6.015C337.9,295.41,335.455,298.339,335.029,303.023Z"
              transform="translate(-68.786 44.994)"
              fill="#50badd"
            />
            <path
              id="Path_70"
              data-name="Path 70"
              d="M292.346,314.832v-18.39c0-.042-.007-.076-.008-.116a16.764,16.764,0,0,1-3.391,4.051,3.213,3.213,0,0,0-.859,1.343v13.112c0,1.97-.906,2.556-3.407,2.874v1.066h11.126v-1.066C293.251,317.388,292.346,316.8,292.346,314.832Z"
              transform="translate(-65.652 44.845)"
              fill="#50badd"
            />
            <path
              id="Path_71"
              data-name="Path 71"
              d="M289.255,284.316c0-.02-.008-.039-.013-.06-.014-.048-.02-.1-.034-.145l-.015,0a2.76,2.76,0,0,0-2.561-1.926,2.7,2.7,0,0,0-2.681,2.614,2.735,2.735,0,0,0,2.675,2.674,2.511,2.511,0,0,0,1.092-.265,10.363,10.363,0,0,1-1.485,4.862,26.641,26.641,0,0,1-5.386,6.257,62.463,62.463,0,0,1-14.374,9.026,155.822,155.822,0,0,1-15.919,6.458l-2.037.7a16.838,16.838,0,0,0-2.084.825,17.037,17.037,0,0,0-3.86,2.391,8.72,8.72,0,0,0-2.744,3.892,7,7,0,0,0-.337,2.459,6.456,6.456,0,0,0,.578,2.5l.04-.022a2.715,2.715,0,0,0,2.5,1.893,2.864,2.864,0,0,0,2.744-2.731,2.729,2.729,0,0,0-2.644-2.7,2.628,2.628,0,0,0-1.254.327,4.931,4.931,0,0,1,.17-1.2A6.9,6.9,0,0,1,243.7,319a14.211,14.211,0,0,1,3.406-2.237,15.639,15.639,0,0,1,1.909-.807l2.031-.752c5.4-2.054,10.767-4.2,15.965-6.733a68.233,68.233,0,0,0,14.726-9.134,28.148,28.148,0,0,0,5.871-6.468,13.493,13.493,0,0,0,1.757-4.133A9.155,9.155,0,0,0,289.255,284.316Z"
              transform="translate(-62.565 45.811)"
              fill="#50badd"
            />
            <path
              id="Path_72"
              data-name="Path 72"
              d="M258.1,294.646v1.066c3.088.852,4.045,1.491.479,10.488l-2.716,6.974c-.319.639-.8.639-1.117,0l-5.111-12.512c-1.437-3.459-1.224-4.1,1.7-4.95v-1.066h-10.6v1.066c2.289.852,2.875,1.491,4.26,4.95l6.389,15.705a8.743,8.743,0,0,1,.213,6.868c-.2.483-.429.954-.666,1.417a21.8,21.8,0,0,1-1.857,3.024c-.273.368-.56.726-.865,1.068-.153.171-.31.338-.472.5-.076.076-.429.372-.392.467.147.089.784-.2.912-.264a6.8,6.8,0,0,0,1.227-.758,8.207,8.207,0,0,0,2.132-2.5,19.334,19.334,0,0,0,1.738-3.434l8.943-22.095c1.332-3.353,2.183-4.1,5.218-4.95v-1.066Z"
              transform="translate(-62.651 44.959)"
              fill="#50badd"
            />
          </g>
        </svg>
        <div class="pp-wrap">
          <router-link class="link" to="/privacy-policy"
            >Privacy Policy</router-link
          >
          <span>2020 &copy; Storyline</span>
        </div>
      </div>
      <div class="column story">
        <h6 class="title">Our Story</h6>
        <p class="paragraph">
          Storyline is a simple way to tell your deceased loved one’s story for
          generations to come. With a beautiful, easy-to-use app, you can
          preserve and share stories, photos, memories, and more, with an
          optional, durable scannable adhesive plaque, others can use their
          smartphone to learn more about the legacy left behind.… for
          generations to come!
        </p>
      </div>
    </div>
    <div class="right">
      <div class="column nav">
        <router-link class="link" to="/how-it-works">How It Works</router-link>
        <router-link class="link" to="/faqs">FAQs</router-link>
        <router-link class="link" to="/contact-us">Contact Us</router-link>
	<router-link class="link" to="/for-mortuaries">For Mortuaries</router-link>
      </div>
      <div class="column social">
     
        <div class="app-link-wrap">
         <a href="https://apps.apple.com/us/app/storyline-share-memories/id1560957153" target="_blank" class="app-link">
            <svg
              class="icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24.069"
              height="29.375"
              viewBox="0 0 24.069 29.375"
            >
              <g transform="translate(-3.135 -1.13)">
                <g data-name="Group 257" transform="translate(3.135 1.13)">
                  <path
                    data-name="Path 92"
                    d="M23.265,16.759a6.606,6.606,0,0,1,3.215-5.6,6.909,6.909,0,0,0-5.431-2.916c-2.292-.233-4.509,1.363-5.665,1.363-1.2,0-2.981-1.341-4.921-1.3a7.212,7.212,0,0,0-6.08,3.673c-2.628,4.51-.667,11.159,1.843,14.814C7.48,28.579,8.97,30.581,10.891,30.5c1.882-.077,2.589-1.186,4.863-1.186,2.254,0,2.92,1.186,4.881,1.146,2.02-.038,3.295-1.787,4.51-3.6A14.433,14.433,0,0,0,27.2,22.689a6.454,6.454,0,0,1-3.941-5.93M19.54,5.835a6.5,6.5,0,0,0,1.509-4.7,6.785,6.785,0,0,0-4.372,2.235,6.1,6.1,0,0,0-1.548,4.529,5.524,5.524,0,0,0,4.411-2.06"
                    transform="translate(-3.135 -1.13)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
            <span>Download</span>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.storylinememorials.v2"
            target="_blank"
            class="app-link"
          >
            <svg
              class="icon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 469.847 469.847"
              style="enable-background:new 0 0 469.847 469.847;"
              xml:space="preserve"
            >
              <path
                style="fill:#2196F3;"
                d="M270.336,234.965L34.39,462.165c-11.049-7.551-17.609-20.111-17.493-33.493V41.258
	C16.781,27.876,23.341,15.316,34.39,7.765L270.336,234.965z"
              />
              <path
                style="fill:#4CAF50;"
                d="M352.896,155.605l-82.56,79.36L34.39,7.765c0.889-0.689,1.815-1.33,2.773-1.92
	c12.47-7.558,28.046-7.802,40.747-0.64L352.896,155.605z"
              />
              <path
                style="fill:#FFC107;"
                d="M452.95,234.965c0.154,15.107-8.055,29.061-21.333,36.267l-78.72,43.093l-82.56-79.36l82.56-79.36
	l78.72,43.093C444.895,205.904,453.104,219.858,452.95,234.965z"
              />
              <path
                style="fill:#F44336;"
                d="M352.896,314.325L77.91,464.725c-12.729,7.051-28.246,6.807-40.747-0.64
	c-0.958-0.59-1.884-1.231-2.773-1.92l235.947-227.2L352.896,314.325z"
              />
            </svg>
            <span>Download</span>
          </a>
        </div>
	
        <div class="social-wrap">
          <a class="icon-wrap" href="/">
            <svg
              class="icon"
              height="512pt"
              viewBox="0 0 512 512"
              width="512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#35445A"
                d="m512 256c0-141.4-114.6-256-256-256s-256 114.6-256 256 114.6 256 256 256c1.5 0 3 0 4.5-.1v-199.2h-55v-64.1h55v-47.2c0-54.7 33.4-84.5 82.2-84.5 23.4 0 43.5 1.7 49.3 2.5v57.2h-33.6c-26.5 0-31.7 12.6-31.7 31.1v40.8h63.5l-8.3 64.1h-55.2v189.5c107-30.7 185.3-129.2 185.3-246.1z"
              />
            </svg>
          </a>
          <a class="icon-wrap" href="/">
            <svg
              class="icon"
              height="512pt"
              viewBox="0 0 512 512"
              width="512pt"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#35445A"
                d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm116.886719 199.601562c.113281 2.519532.167969 5.050782.167969 7.59375 0 77.644532-59.101563 167.179688-167.183594 167.183594h.003906-.003906c-33.183594 0-64.0625-9.726562-90.066406-26.394531 4.597656.542969 9.277343.8125 14.015624.8125 27.53125 0 52.867188-9.390625 72.980469-25.152344-25.722656-.476562-47.410156-17.464843-54.894531-40.8125 3.582031.6875 7.265625 1.0625 11.042969 1.0625 5.363281 0 10.558593-.722656 15.496093-2.070312-26.886718-5.382813-47.140624-29.144531-47.140624-57.597657 0-.265624 0-.503906.007812-.75 7.917969 4.402344 16.972656 7.050782 26.613281 7.347657-15.777343-10.527344-26.148437-28.523438-26.148437-48.910157 0-10.765624 2.910156-20.851562 7.957031-29.535156 28.976563 35.554688 72.28125 58.9375 121.117187 61.394532-1.007812-4.304688-1.527343-8.789063-1.527343-13.398438 0-32.4375 26.316406-58.753906 58.765625-58.753906 16.902344 0 32.167968 7.144531 42.890625 18.566406 13.386719-2.640625 25.957031-7.53125 37.3125-14.261719-4.394531 13.714844-13.707031 25.222657-25.839844 32.5 11.886719-1.421875 23.214844-4.574219 33.742187-9.253906-7.863281 11.785156-17.835937 22.136719-29.308593 30.429687zm0 0"
              />
            </svg>
          </a>
          <a class="icon-wrap" href="/">
            <svg
              class="icon"
              height="512pt"
              viewBox="0 0 512 512"
              width="512pt"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#35445A"
                d="m305 256c0 27.0625-21.9375 49-49 49s-49-21.9375-49-49 21.9375-49 49-49 49 21.9375 49 49zm0 0"
              />
              <path
                fill="#35445A"
                d="m370.59375 169.304688c-2.355469-6.382813-6.113281-12.160157-10.996094-16.902344-4.742187-4.882813-10.515625-8.640625-16.902344-10.996094-5.179687-2.011719-12.960937-4.40625-27.292968-5.058594-15.503906-.707031-20.152344-.859375-59.402344-.859375-39.253906 0-43.902344.148438-59.402344.855469-14.332031.65625-22.117187 3.050781-27.292968 5.0625-6.386719 2.355469-12.164063 6.113281-16.902344 10.996094-4.882813 4.742187-8.640625 10.515625-11 16.902344-2.011719 5.179687-4.40625 12.964843-5.058594 27.296874-.707031 15.5-.859375 20.148438-.859375 59.402344 0 39.25.152344 43.898438.859375 59.402344.652344 14.332031 3.046875 22.113281 5.058594 27.292969 2.359375 6.386719 6.113281 12.160156 10.996094 16.902343 4.742187 4.882813 10.515624 8.640626 16.902343 10.996094 5.179688 2.015625 12.964844 4.410156 27.296875 5.0625 15.5.707032 20.144532.855469 59.398438.855469 39.257812 0 43.90625-.148437 59.402344-.855469 14.332031-.652344 22.117187-3.046875 27.296874-5.0625 12.820313-4.945312 22.953126-15.078125 27.898438-27.898437 2.011719-5.179688 4.40625-12.960938 5.0625-27.292969.707031-15.503906.855469-20.152344.855469-59.402344 0-39.253906-.148438-43.902344-.855469-59.402344-.652344-14.332031-3.046875-22.117187-5.0625-27.296874zm-114.59375 162.179687c-41.691406 0-75.488281-33.792969-75.488281-75.484375s33.796875-75.484375 75.488281-75.484375c41.6875 0 75.484375 33.792969 75.484375 75.484375s-33.796875 75.484375-75.484375 75.484375zm78.46875-136.3125c-9.742188 0-17.640625-7.898437-17.640625-17.640625s7.898437-17.640625 17.640625-17.640625 17.640625 7.898437 17.640625 17.640625c-.003906 9.742188-7.898437 17.640625-17.640625 17.640625zm0 0"
              />
              <path
                fill="#35445A"
                d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm146.113281 316.605469c-.710937 15.648437-3.199219 26.332031-6.832031 35.683593-7.636719 19.746094-23.246094 35.355469-42.992188 42.992188-9.347656 3.632812-20.035156 6.117188-35.679687 6.832031-15.675781.714844-20.683594.886719-60.605469.886719-39.925781 0-44.929687-.171875-60.609375-.886719-15.644531-.714843-26.332031-3.199219-35.679687-6.832031-9.8125-3.691406-18.695313-9.476562-26.039063-16.957031-7.476562-7.339844-13.261719-16.226563-16.953125-26.035157-3.632812-9.347656-6.121094-20.035156-6.832031-35.679687-.722656-15.679687-.890625-20.6875-.890625-60.609375s.167969-44.929688.886719-60.605469c.710937-15.648437 3.195312-26.332031 6.828125-35.683593 3.691406-9.808594 9.480468-18.695313 16.960937-26.035157 7.339844-7.480469 16.226563-13.265625 26.035157-16.957031 9.351562-3.632812 20.035156-6.117188 35.683593-6.832031 15.675781-.714844 20.683594-.886719 60.605469-.886719s44.929688.171875 60.605469.890625c15.648437.710937 26.332031 3.195313 35.683593 6.824219 9.808594 3.691406 18.695313 9.480468 26.039063 16.960937 7.476563 7.34375 13.265625 16.226563 16.953125 26.035157 3.636719 9.351562 6.121094 20.035156 6.835938 35.683593.714843 15.675781.882812 20.683594.882812 60.605469s-.167969 44.929688-.886719 60.605469zm0 0"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.footer-wrap {
  background: var(--color-3);
  display: flex;
  padding-top: 50px;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    padding-bottom: 50px;
  }
}

.left,
.right {
  display: flex;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.left {
  border-right: solid 1px #707070;
  width: 60%;
  @media screen and (max-width: 1440px) {
    width: auto;
  }
  @media screen and (max-width: 1024px) {
    border-right: none;
    width: 50%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
  }
}

.right {
  width: 40%;
  @media screen and (max-width: 1440px) {
    width: auto;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
  }
}

.column {
  border-right: solid 1px #707070;
  padding: 0 30px 50px;
  @media screen and (max-width: 1024px) {
    border-right: none;
    width: 100%;
  }
  &:last-child {
    border-right: none;
  }
}

.privacy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 650px) {
    align-items: center;
    text-align: center;
  }
  .logo {
  }
  .pp-wrap {
    .link {
      text-decoration: none;
      &:link,
      &:visited {
        color: var(--white);
      }
      &:hover,
      &:active {
        color: var(--color-1);
      }
    }
    span {
      display: block;
      color: var(--white);
    }
  }
}

.story {
  // max-width: 685px;
  @media screen and (max-width: 650px) {
    text-align: center;
  }
  .title {
    color: var(--color-1);
    font-family: 'sans-bold';
    font-size: 2.4rem;
  }
  .paragraph {
    color: var(--white);
    font-size: 1.8rem;
    margin-top: 20px;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  padding-top: 45px;
  @media screen and (max-width: 1024px) {
    align-items: flex-end;
    padding-top: 0;
  }
  @media screen and (max-width: 650px) {
    align-items: center;
  }
  .link {
    font-size: 2rem;
    margin-bottom: 15px;
    text-decoration: none;
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0;
    }
    &:link,
    &:visited {
      color: var(--white);
    }
    &:hover,
    &:active {
      color: var(--color-1);
    }
  }
}

.social {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40px;
  @media screen and (max-width: 1024px) {
    align-items: flex-end;
    padding: 0 30px 0 0;
  }
  @media screen and (max-width: 650px) {
    align-items: center;
    padding: 0;
  }
}

.app-link-wrap {
  display: flex;
}

.social-wrap {
  display: none;
  @media screen and (max-width: 1024px) {
    margin-top: 30px;
  }
}

.app-link {
  align-items: center;
  display: flex;
  text-decoration: none;
  &:first-child {
    margin-right: 20px;
  }
  .icon {
    margin-right: 10px;
    width: 25px;
  }
  span {
    color: var(--white);
    font-family: 'sans-bold';
    font-size: 2rem;
  }
}

.icon-wrap {
  margin-right: 25px;
  &:last-child {
    margin-right: 0;
  }
  .icon {
    border-radius: 50%;
    background: var(--color-1);
    display: block;
    height: 55px;
    width: 55px;
  }
}
</style>
