<template>
  <div class="free-wrap">
    <img class="bg" src="/img/free-bg.jpg" />
    <div class="content-block">
      <p class="title text-color-1">
        Storyline is free to download for both iOS and Android.
      </p>
      <p class="paragraph text-white">
        If you’d like to store and share memories, stories, photos, and more,
        your mortuary will collect a one-time fee to activate your loved one’s
        profile.
      </p>
      <AppLinks />
    </div>
  </div>
</template>

<script>
import AppLinks from '@/components/AppLinks.vue'
export default {
  components: {
    AppLinks
  }
}
</script>

<style lang="scss" scoped>
.free-wrap {
  position: relative;
  text-align: center;
  .bg {
    display: block;
    width: 100%;
    @media screen and (max-width: 1024px) {
      height: 400px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
    }
  }
  .content-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 650px) {
      width: 80%;
    }
  }
}
</style>
