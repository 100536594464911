<template>
  <SubPageWrap title="Join Our Platform">
    <div class="section-wrap contact-wrap">
      <div class="content-squeeze">
        <div class="column">
          <div class="contact-container">
            <div
              class="message-wrap"
              v-if="this.errorList.length || this.successMessage"
            >
              <div v-if="this.errorList.length">
                <p
                  class="paragraph error"
                  v-for="(error, index) in errorList"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
              <p class="paragraph success" v-if="this.successMessage">
                {{ successMessage }}
              </p>
            </div>
            <form v-on:submit.prevent="formSubmit">
              <div class="input-group">
                <input
                  class="input"
                  :class="funeralHomeNameError ? 'has-error' : ''"
                  placeholder="Mortuary Name"
                  v-model="funeralHomeName"
                />
              </div>
              <div class="input-group">
                <input
                  class="input"
                  :class="contactNameError ? 'has-error' : ''"
                  placeholder="Contact Person Name"
                  v-model="contactName"
                />
              </div>
              <div class="input-group">
                <input
                  class="input"
                  :class="phoneNumberError ? 'has-error' : ''"
                  placeholder="Phone Number"
                  v-model="phoneNumber"
                />
              </div>
              <div class="input-group">
                <input
                  class="input"
                  :class="emailError ? 'has-error' : ''"
                  placeholder="Email Address"
                  v-model="email"
                />
              </div>
              <div class="input-group">
                <input
                  class="input"
                  :class="websiteError ? 'has-error' : ''"
                  placeholder="Mortuary Website"
                  v-model="website"
                />
              </div>
              <button class="btn btn-gradient">
                Submit Request
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Free />
  </SubPageWrap>
</template>

<script>
import SubPageWrap from '@/components/layout/SubPageWrap.vue'
import Free from '@/components/Free.vue'
import axios from 'axios'
export default {
  metaInfo: {
    title: 'Join Our Platform'
  },
  data() {
    return {
      funeralHomeName: '',
      contactName: '',
      website: '',
      email: '',
      phoneNumber: '',
      city: '',
      state: '',
      funeralHomeNameError: false,
      contactNameError: false,
      phoneNumberError: false,
      websiteError: false,
      emailError: false,
      errorList: [],
      successMessage: ''
    }
  },
  components: {
    SubPageWrap,
    Free
  },
  methods: {
    formSubmit() {
      this.errorList = []
      if (
        this.funeralHomeName &&
        this.contactName &&
        this.website &&
        this.email &&
        this.phoneNumber
      ) {
        let payload = {
          funeralHomeName: this.funeralHomeName,
          contactName: this.contactName,
          website: this.website,
          email: this.email,
          phoneNumber: this.phoneNumber,
          subject: 'Join Platform Submission'
        }

        try {
          const response = axios.post(`https://formspree.io/xwkrgngj`, payload)
          response.then(val => {
            if (val.status === 200) {
              this.successMessage = 'Thank you! We will be in touch shortly.'
            }
            this.funeralHomeName = ''
            this.contactName = ''
            this.phoneNumber = ''
            this.website = ''
            this.email = ''
            this.message = ''
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        if (!this.funeralHomeName) {
          this.funeralHomeNameError = true
          this.errorList.push('Please enter the name of the mortuary')
        } else {
          this.funeralHomeNameError = false
        }
        if (!this.contactName) {
          this.contactNameError = true
          this.errorList.push('Please enter a contact person name')
        } else {
          this.contactNameError = false
        }
        if (!this.phoneNumber) {
          this.phoneNumberError = true
          this.errorList.push('Please enter a phone number')
        } else {
          this.phoneNumberError = false
        }
        if (!this.website) {
          this.websiteError = true
          this.errorList.push('Please enter the mortuary website')
        } else {
          this.websiteError = false
        }
        if (!this.email) {
          this.emailError = true
          this.errorList.push('Please enter an email')
        } else {
          this.emailError = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-wrap {
  padding: 100px 0;
  @media screen and (max-width: 991px) {
    padding: 50px 0;
  }
  .content-squeeze {
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
  .column {
    width: 50%;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}
.contact-details {
  max-width: 555px;
  @media screen and (max-width: 991px) {
    max-width: 500px;
    margin: 0 auto;
  }
  .title {
    color: var(--color-2);
    font-family: 'sans-light';
    font-size: 4.4rem;
    margin-bottom: 50px;
    @media screen and (max-width: 991px) {
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      font-size: 3.6rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 2.4rem;
    }
  }
  .detail {
    color: var(--gray-2);
    display: flex;
    font-family: 'sans-light';
    font-size: 2.4rem;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
    span {
      font-family: 'sans-bold';
      text-align: right;
    }
  }
}

.message-wrap {
  .paragraph {
    font-size: 2rem;
    margin-top: 10px;
    &.error {
      color: #ff0000;
    }
    &.success {
      color: green;
    }
  }
}

.contact-container {
  max-width: 600px;
  padding-left: 50px;
  @media screen and (max-width: 991px) {
    margin: 50px auto 0;
    max-width: 500px;
    padding-left: 0;
  }
  .input-group {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
  .btn {
    margin-top: 50px;
  }
}
</style>
