<template>
  <SubPageWrap title="For Mortuaries">
    <div class="section-wrap worth-wrap">
      <img class="worth-bg" src="/img/mortuaries/hero-bg.jpg" />
      <div class="worth-content">
        <div class="content-squeeze">
          <div class="content-block">
            <p class="title text-white">
              Because Their Loved One’s Story is Worth Telling… and Preserving
            </p>
            <p class="subtitle text-white">
              You’ve helped honor and celebrate a life well lived. Now, you can help their story live on.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-wrap what-wrap">
      <div class="content-squeeze">
        <div class="content-block">
          <p class="title text-color-2">What is Storyline?</p>
          <p class="subtitle text-color-2 text-bold">
            Storyline is an additional service you can offer your clients that
            consists of:
          </p>
        </div>
        <div class="column-wrap">
          <div class="column">
            <div class="simple-step">
              <img class="w-phone step-1" src="/img/mortuaries/phone-1.png" />
              <p class="paragraph">
                The Storyline app that lets your client create and manage a
                lasting profile for their loved one.
              </p>
            </div>
          </div>
          <div class="column">
            <div class="simple-step">
              <img class="w-phone step-2" src="/img/mortuaries/phone-2.png" />
              <p class="paragraph">
                An optional durable, scannable QR Plaque adhesive for affixing
                to a headstone or other memorial.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="happens-wrap">
      <div class="content-squeeze">
        <p class="title text-color-1">
          What happens after purchasing a Storyline App upgrade Kit from you?
        </p>
        <div class="happens-text">
          <p class="paragraph text-white">
            Your clients can write life sketches, preserve obituaries, upload
            photos, and collect memories and condolences from their loved one’s
            friends and family.
          </p>
          <p class="paragraph text-white">
            They also have the option to order a Storyline Plaque – complete
            with a unique QR codePlaque and instructions for affixing it to
            their loved one’s headstone or other memorial. Others can then use
            their smartphones to scan the code, open a webpage to view the
            content, and leave condolences or submit memories of their own.
          </p>
        </div>
      </div>
    </div>
    <p class="simple">Working with Storyline is Simple</p>
    <div class="section-wrap step-wrap step-1">
      <div class="content-squeeze">
        <div class="column">
          <div class="content-block">
            <Number :number="1" />
            <p class="subtitle text-color-2">
              Activate the Storyline Profile
            </p>
            <p class="paragraph text-gray-2">
              The Storyline app (free to download on both the Apple App Store
              and Google Play) allows your clients to upload an obituary and
              details about memorial services. If they’d like to upgrade to a
              paid account for storing and sharing memories and photos,
              collecting comments and condolences from others, and more, you’ll
              simply collect the fee and submit your client’s email address to
              Storyline.
            </p>
          </div>
        </div>
        <div class="phone-wrap">
          <img class="phone" src="/img/mortuaries/phone-3-review-v2.png" />
        </div>
        <div class="column bg-wrap">
          <img class="step-img" src="/img/mortuaries/step-1.jpg" />
        </div>
      </div>
    </div>
    <div class="section-wrap step-wrap step-2">
      <div class="content-squeeze">
        <div class="phone-wrap">
          <img class="phone" src="/img/mortuaries/phone-4-pair-v2.png" />
        </div>
        <div class="column bg-wrap">
          <img class="step-img" src="/img/mortuaries/step-2.jpg" />
        </div>
        <div class="column">
          <div class="content-block">
            <Number :number="2" />
            <p class="subtitle text-color-2">
              Request an Optional Storyline Plaque Application Kit
            </p>
            <p class="paragraph text-gray-2">
              Storyline Plaques can be ordered via the Storyline app. You will
              have the option to receive the kits at your place of business or
              send them directly to your clients if they wish to apply the
              adhesive Storyline QR Plaque themselves.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-wrap step-wrap step-3">
      <div class="content-squeeze">
        <div class="column">
          <div class="content-block">
            <Number :number="3" />
            <p class="subtitle text-color-2">
              Let Their Memory Live On
            </p>
            <p class="paragraph text-gray-2">
              When they were born and when they passed are only two moments in a
              lifetime, yet they’re often the only details their monument
              preserves. With Storyline, your clients can share memories,
              stories, comments, photos, and more with family, friends, and
              generations
            </p>
          </div>
        </div>
        <div class="phone-wrap">
          <img class="phone" src="/img/mortuaries/phone-5.png" />
        </div>
        <div class="column bg-wrap">
          <img class="step-img" src="/img/mortuaries/step-3.jpg" />
        </div>
      </div>
    </div>
    <div class="request-wrap">
      <div class="content-block">
        <p class="title text-color-1">
          Request More Information &amp; Get Started Today!
        </p>
        <p class="paragraph text-white">
          To begin offering Storyline and Storyline Plaques to your clients,
          simply contact us using the simple form below so we can verify your
          business and provide detailed information.
        </p>
        <div class="newsletter-wrap">
          <div
            class="message-wrap"
            v-if="this.emailError || this.nameError || this.successMessage"
          >
            <p class="paragraph error" v-if="this.emailError">
              {{ emailError }}
            </p>
            <p class="paragraph error" v-if="this.nameError">
              {{ nameError }}
            </p>
            <p class="paragraph success" v-if="this.successMessage">
              {{ successMessage }}
            </p>
          </div>
          <form v-on:submit.prevent="formSubmit">
            <div class="row">
              <div class="input-group">
                <input
                  class="input"
                  placeholder="Full Name"
                  :class="nameError ? 'has-error' : ''"
                  v-model="fullName"
                />
              </div>
              <div class="input-group">
                <input
                  class="input"
                  placeholder="Email Address"
                  :class="nameError ? 'has-error' : ''"
                  v-model="email"
                />
              </div>
            </div>
            <button class="btn btn-white">Get More Information</button>
          </form>
        </div>
      </div>
    </div>
  </SubPageWrap>
</template>

<script>
import SubPageWrap from '@/components/layout/SubPageWrap.vue'
import Number from '@/components/Number.vue'
import axios from 'axios'
export default {
  metaInfo: {
    title: 'For Morturaries | Storyline'
  },
  data() {
    return {
      fullName: '',
      email: '',
      nameError: '',
      emailError: '',
      successMessage: ''
    }
  },
  components: {
    SubPageWrap,
    Number
  },
  methods: {
    formSubmit() {
      if (this.email && this.fullName) {
        let payload = {
          fullName: this.fullName,
          email: this.email,
          subject: 'For Mortuaries - More Information'
        }

        try {
          const response = axios.post(`https://formspree.io/xvowebey`, payload)
          response.then(val => {
            if (val.status === 200) {
              this.successMessage = 'Thank you! We will be in touch shortly.'
            }
            this.fullName = ''
            this.email = ''
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        if (!this.fullName) {
          this.nameError = 'Please enter your full name.'
        } else {
          this.nameError = ''
        }
        if (!this.email) {
          this.emailError = 'Please enter a valid email address.'
        } else {
          this.emailError = ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.worth-wrap {
  padding: 0;
  position: relative;
  .worth-bg {
    display: block;
    width: 100%;
    @media screen and (max-width: 1024px) {
      height: 475px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
    }
    @media screen and (max-width: 650px) {
      height: 300px;
    }
  }
}
.worth-content {
  align-items: center;
  display: flex;
  height: 100%;
  left: 0;
  max-width: 625px;
  position: absolute;
  top: 0;
  width: 100%;
  @media screen and (max-width: 650px) {
    text-align: center;
  }
}
.what-wrap {
  padding: 100px 0;
  @media screen and (max-width: 991px) {
    padding: 50px 0;
  }
  .content-squeeze {
    flex-direction: column;
    .column {
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }
  .content-block {
    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }
  .column-wrap {
    display: flex;
    margin-top: 100px;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      margin-top: 50px;
    }
    .column {
      &:first-child {
        padding-right: 25px;
        @media screen and (max-width: 1024px) {
          padding-right: 0;
        }
      }
      &:last-child {
        padding-left: 25px;
        @media screen and (max-width: 1024px) {
          padding-left: 0;
        }
      }
    }
  }
  .simple-step {
    position: relative;
    @media screen and (max-width: 650px) {
      padding-bottom: 100px;
    }
    .w-phone {
      display: block;
      width: 100%;
      &.step-1 {
        max-width: 800px;
        @media screen and (max-width: 650px) {
          max-width: 300px;
          margin: 0 auto;
        }
      }
      &.step-2 {
        max-width: 800px;
        @media screen and (max-width: 650px) {
          max-width: 320px;
          margin: 0 auto;
          transform: translateX(5px);
        }
      }
    }
    .paragraph {
      color: var(--gray-2);
      font-size: 2.4rem;
      line-height: 1.33;
      position: absolute;
      right: 0;
      top: 22%;
      width: 45%;
      z-index: 1;
      @media screen and (max-width: 1440px) {
        font-size: 2rem;
      }
      @media screen and (max-width: 650px) {
        bottom: 30px;
        font-size: 1.8rem;
        text-align: center;
        top: auto;
        width: 100%;
      }
    }
  }
}
.step-wrap {
  @media screen and (max-width: 767px) {
    margin-top: 50px;
  }
  &.step-1 {
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
  &.step-1,
  &.step-3 {
    .content-block {
      padding: 0 225px 0 105px;
      @media screen and (max-width: 1439px) {
        padding: 0 125px 0 50px;
      }
      @media screen and (max-width: 1024px) {
        padding: 0 50px;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
      }
      @media screen and (max-width: 650px) {
        padding: 0 20px;
      }
    }
  }
  &.step-2 {
    .column {
      @media screen and (max-width: 767px) {
        order: 1;
      }
    }
    .phone-wrap {
      @media screen and (max-width: 767px) {
        order: 2;
      }
    }
    .content-block {
      padding: 0 105px 0 225px;
      @media screen and (max-width: 1439px) {
        padding: 0 50px 0 125px;
      }
      @media screen and (max-width: 1024px) {
        padding: 0 50px;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
      }
      @media screen and (max-width: 650px) {
        padding: 0 20px;
      }
    }
  }
  .content-squeeze {
    padding: 0;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .column {
      align-items: center;
      display: flex;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .bg-wrap {
    @media screen and (max-width: 1024px) {
      display: none !important;
    }
  }
  .phone-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    @media screen and (max-width: 1600px) {
      width: 320px;
    }
    @media screen and (max-width: 1439px) {
      width: 220px;
    }
    @media screen and (max-width: 1024px) {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      width: 50%;
    }
    @media screen and (max-width: 767px) {
      margin-top: 50px;
      width: 100%;
    }
    .phone {
      display: block;
      width: 100%;
      @media screen and (max-width: 1024px) {
        margin: 0 auto;
        width: 300px;
      }
    }
  }
}
.content-squeeze {
  .column {
    width: 50%;
    .step-img {
      display: block;
      width: 100%;
    }
  }
}
.happens-wrap {
  background: var(--color-3);
  padding: 100px 0;
  @media screen and (max-width: 991px) {
    padding: 50px 0;
    text-align: center;
  }

  .content-squeeze {
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
  .title {
    font-family: 'sans-light';
    font-size: 4.4rem;
    line-height: 1.25;
    min-width: 600px;
    @media screen and (max-width: 1439px) {
      font-size: 3.6rem;
      min-width: 420px;
      padding-right: 20px;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: 650px) {
      font-size: 2.4rem;
      min-width: 100%;
    }
  }
  .paragraph {
    font-size: 1.8rem;
    line-height: 1.75;
    &:last-child {
      margin-top: 40px;
    }
  }
}
.simple {
  color: var(--color-2);
  font-family: 'sans-light';
  font-size: 4.4rem;
  padding: 75px 20px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    padding: 50px 20px;
  }
  @media screen and (max-width: 767px) {
    font-size: 3.6rem;
  }
}
.request-wrap {
  background: var(--color-2);
  padding: 50px 20px 60px;
  text-align: center;
  .paragraph {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }
  .newsletter-wrap {
    margin-top: 50px;
    .row {
      display: flex;
      justify-content: center;
      @media screen and (max-width: 650px) {
        flex-direction: column;
      }
    }
    .input-group {
      max-width: 600px;
      padding: 0 30px;
      width: 100%;
      @media screen and (max-width: 650px) {
        padding: 0;
      }
      .input {
        border-bottom-color: var(--white);
        color: var(--color-1);
        &::placeholder {
          color: var(--color-1);
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: var(--color-1);
        }
        &::-ms-input-placeholder {
          color: var(--color-1);
        }
      }
    }
    .btn {
      margin-top: 75px;
    }
  }
}
.message-wrap {
  .paragraph {
    font-size: 2rem;
    margin-top: 10px;
    line-height: 1;
    &.error {
      color: #ff0000;
    }
    &.success {
      color: green;
    }
  }
}
</style>
