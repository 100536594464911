<template>
  <SubPageWrap title="Contact Us">
    <div class="section-wrap contact-wrap">
      <div class="content-squeeze">
        <div class="column">
          <div class="contact-details">
            <p class="title">
              Please reach out with any questions or comments.
            </p>
            <p class="detail">Email. <span>hello@storyline.co</span></p>
          </div>
        </div>
        <div class="column">
          <div class="contact-container">
            <div
              class="message-wrap"
              v-if="
                this.emailError ||
                  this.nameError ||
                  this.messageError ||
                  this.successMessage
              "
            >
              <p class="paragraph error" v-if="this.emailError">
                {{ emailError }}
              </p>
              <p class="paragraph error" v-if="this.nameError">
                {{ nameError }}
              </p>
              <p class="paragraph error" v-if="this.messageError">
                {{ messageError }}
              </p>
              <p class="paragraph success" v-if="this.successMessage">
                {{ successMessage }}
              </p>
            </div>
            <form v-on:submit.prevent="formSubmit">
              <div class="input-group">
                <input
                  class="input"
                  :class="nameError ? 'has-error' : ''"
                  placeholder="Full Name"
                  v-model="fullName"
                />
              </div>
              <div class="input-group">
                <input
                  class="input"
                  :class="emailError ? 'has-error' : ''"
                  placeholder="Email Address"
                  v-model="email"
                />
              </div>
              <div class="input-group">
                <textarea
                  class="textarea"
                  :class="messageError ? 'has-error' : ''"
                  placeholder="Message"
                  v-model="message"
                ></textarea>
              </div>
              <button class="btn btn-gradient">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Free />
  </SubPageWrap>
</template>

<script>
import SubPageWrap from '@/components/layout/SubPageWrap.vue'
import Free from '@/components/Free.vue'
import axios from 'axios'
export default {
  metaInfo: {
    title: 'Contact Us'
  },
  data() {
    return {
      fullName: '',
      email: '',
      message: '',
      nameError: '',
      emailError: '',
      messageError: '',
      successMessage: ''
    }
  },
  components: {
    SubPageWrap,
    Free
  },
  methods: {
    formSubmit() {
      if (this.email && this.fullName && this.message) {
        let payload = {
          fullName: this.fullName,
          email: this.email,
          message: this.message,
          subject: 'Contact Form Submission'
        }

        try {
          const response = axios.post(`https://formspree.io/xgenjapj`, payload)
          response.then(val => {
            if (val.status === 200) {
              this.successMessage = 'Thank you! We will be in touch shortly.'
            }
            this.fullName = ''
            this.email = ''
            this.message = ''
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        if (!this.fullName) {
          this.nameError = 'Please enter your full name.'
        } else {
          this.nameError = ''
        }
        if (!this.email) {
          this.emailError = 'Please enter a valid email address.'
        } else {
          this.emailError = ''
        }
        if (!this.message) {
          this.messageError = 'Please add a message.'
        } else {
          this.messageError = ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-wrap {
  padding: 100px 0;
  @media screen and (max-width: 991px) {
    padding: 50px 0;
  }
  .content-squeeze {
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
  .column {
    width: 50%;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}
.contact-details {
  max-width: 555px;
  @media screen and (max-width: 991px) {
    max-width: 500px;
    margin: 0 auto;
  }
  .title {
    color: var(--color-2);
    font-family: 'sans-light';
    font-size: 4.4rem;
    margin-bottom: 50px;
    @media screen and (max-width: 991px) {
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      font-size: 3.6rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 2.4rem;
    }
  }
  .detail {
    color: var(--gray-2);
    display: flex;
    font-family: 'sans-light';
    font-size: 2.4rem;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
    span {
      font-family: 'sans-bold';
      text-align: right;
    }
  }
}

.message-wrap {
  .paragraph {
    font-size: 2rem;
    margin-top: 10px;
    &.error {
      color: #ff0000;
    }
    &.success {
      color: green;
    }
  }
}

.contact-container {
  max-width: 600px;
  padding-left: 50px;
  @media screen and (max-width: 991px) {
    margin: 50px auto 0;
    max-width: 500px;
    padding-left: 0;
  }
  .input-group {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
  .btn {
    margin-top: 50px;
  }
}
</style>
