<template>
  <SubPageWrap title="Privacy Policy">
    <div class="pp-wrap">
      <div class="content-squeeze">
        <p class="paragraph">Last updated: August 20, 2020</p>

        <p class="paragraph">
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>

        <p class="paragraph">
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>

        <h3 class="title">Interpretation and Definitions</h3>
        <h4 class="subtitle">Interpretation</h4>
        <p class="paragraph">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>

        <h4 class="subtitle">Definitions</h4>
        <p class="paragraph">For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <span>Account</span> means a unique account created for You to
            access our Service or parts of our Service.
          </li>

          <li>
            <span>Affiliate</span> means an entity that controls, is controlled
            by or is under common control with a party, where "control" means
            ownership of 50% or more of the shares, equity interest or other
            securities entitled to vote for election of directors or other
            managing authority.
          </li>

          <li>
            <span>Application</span> means the software program provided by the
            Company downloaded by You on any electronic device, named Storyline
          </li>

          <li>
            <span>Business</span>, for the purpose of the CCPA (California
            Consumer Privacy Act), refers to the Company as the legal entity
            that collects Consumers' personal information and determines the
            purposes and means of the processing of Consumers' personal
            information, or on behalf of which such information is collected and
            that alone, or jointly with others, determines the purposes and
            means of the processing of consumers' personal information, that
            does business in the State of California.
          </li>

          <li>
            <span>Company</span> (referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement) refers to Heritage Platforms LLC,
            276 W 1030 S Logan UT 84321.
          </li>

          <p class="paragraph">
            For the purpose of the GDPR, the Company is the Data Controller.
          </p>

          <li>
            <span>Consumer</span>, for the purpose of the CCPA (California
            Consumer Privacy Act), means a natural person who is a California
            resident. A resident, as defined in the law, includes (1) every
            individual who is in the USA for other than a temporary or
            transitory purpose, and (2) every individual who is domiciled in the
            USA who is outside the USA for a temporary or transitory purpose.
          </li>

          <li>
            <span>Cookies</span> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </li>

          <li><span>Country</span> refers to: Utah, United States</li>

          <li>
            <span>Data Controller</span>, for the purposes of the GDPR (General
            Data Protection Regulation), refers to the Company as the legal
            person which alone or jointly with others determines the purposes
            and means of the processing of Personal Data.
          </li>

          <li>
            <span>Device</span> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </li>

          <li>
            <span>Do Not Track (DNT)</span> is a concept that has been promoted
            by US regulatory authorities, in particular the U.S. Federal Trade
            Commission (FTC), for the Internet industry to develop and implement
            a mechanism for allowing internet users to control the tracking of
            their online activities across websites.
          </li>

          <li>
            <span>Personal Data</span> is any information that relates to an
            identified or identifiable individual.
          </li>

          <p class="paragraph">
            For the purposes for GDPR, Personal Data means any information
            relating to You such as a name, an identification number, location
            data, online identifier or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity.
          </p>

          <p class="paragraph">
            For the purposes of the CCPA, Personal Data means any information
            that identifies, relates to, describes or is capable of being
            associated with, or could reasonably be linked, directly or
            indirectly, with You.
          </p>

          <li>
            <span>Sale</span>, for the purpose of the CCPA (California Consumer
            Privacy Act), means selling, renting, releasing, disclosing,
            disseminating, making available, transferring, or otherwise
            communicating orally, in writing, or by electronic or other means, a
            Consumer's Personal information to another business or a third party
            for monetary or other valuable consideration.
          </li>

          <li>
            <span>Service</span> refers to the Application or the Website or
            both.
          </li>

          <li>
            <span>Service Provider</span> means any natural or legal person who
            processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used. For the purpose of the
            GDPR, Service Providers are considered Data Processors.
          </li>

          <li>
            <span>Third-party Social Media Service</span> refers to any website
            or any social network website through which a User can log in or
            create an account to use the Service.
          </li>

          <li>
            <span>Usage Data</span> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </li>

          <li>
            <span>Website</span> refers to Storyline, accessible from
            https://storyline.co
          </li>

          <li>
            <span>You</span> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </li>
        </ul>
        <p class="paragarph">
          Under GDPR (General Data Protection Regulation), You can be referred
          to as the Data Subject or as the User as you are the individual using
          the Service.
        </p>

        <h3 class="title">Collecting and Using Your Personal Data</h3>
        <h4 class="subtitle">Types of Data Collected</h4>
        <h5 class="metatitle">Personal Data</h5>
        <p class="paragraph">
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>Email address</li>

          <li>First name and last name</li>

          <li>Phone number</li>

          <li>Address, State, Province, ZIP/Postal code, City</li>

          <li>Usage Data</li>
        </ul>
        <h5 class="metatitle">Usage Data</h5>
        <p class="paragraph">
          Usage Data is collected automatically when using the Service.
        </p>

        <p class="paragraph">
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>

        <p class="paragraph">
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>

        <p class="paragraph">
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>

        <h5 class="metatitle">
          Information Collected while Using the Application
        </h5>
        <p class="paragraph">
          While using Our Application, in order to provide features of Our
          Application, We may collect, with your prior permission:
        </p>
        <ul>
          <li>Information regarding your location</li>
          <li>Information from your Device's phone book (contacts list)</li>
          <li>
            Pictures and other information from your Device's camera and photo
            library
          </li>
        </ul>
        <p class="paragraph">
          We use this information to provide features of Our Service, to improve
          and customize Our Service. The information may be uploaded to the
          Company's servers and/or a Service Provider's server or it be simply
          stored on Your device.
        </p>

        <p class="paragraph">
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </p>

        <h5 class="metatitle">Tracking Technologies and Cookies</h5>
        <p class="paragraph">
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service.
        </p>

        <p class="paragraph">
          You can instruct Your browser to refuse all Cookies or to indicate
          when a Cookie is being sent. However, if You do not accept Cookies,
          You may not be able to use some parts of our Service.
        </p>

        <p class="paragraph">
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close your web
          browser. Learn more about cookies: All About Cookies.
        </p>

        <p class="paragraph">
          We use both session and persistent Cookies for the purposes set out
          below:
        </p>
        <ul>
          <li>
            <span>Necessary / Essential Cookies</span>
            <br />
            Type: Session Cookies
            <br />
            Administered by: Us
            <br />
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </li>
          <li>
            <span>Cookies Policy / Notice Acceptance Cookies</span>
            <br />
            Type: Persistent Cookies
            <br />
            Administered by: Us
            <br />
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </li>
          <li>
            <span>Functionality Cookies</span>
            <br />
            Type: Persistent Cookies
            <br />
            Administered by: Us
            <br />
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </li>
        </ul>
        <p class="paragraph">
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>

        <h4 class="subtitle">Use of Your Personal Data</h4>
        <p class="paragraph">
          The Company may use Personal Data for the following purposes:
        </p>
        <ul>
          <li>
            <span>To provide and maintain our Service</span>, including to
            monitor the usage of our Service.
          </li>
          <li>
            <span>To manage Your Account:</span> to manage Your registration as
            a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user.
          </li>
          <li>
            <span>For the performance of a contract:</span> the development,
            compliance and undertaking of the purchase contract for the
            products, items or services You have purchased or of any other
            contract with Us through the Service.
          </li>
          <li>
            <span>To contact You:</span> To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application's push notifications regarding updates
            or informative communications related to the functionalities,
            products or contracted services, including the security updates,
            when necessary or reasonable for their implementation.
          </li>
          <li>
            <span>To provide You</span> with news, special offers and general
            information about other goods, services and events which we offer
            that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information.
          </li>
          <li>
            <span>To manage Your requests:</span> To attend and manage Your
            requests to Us.
          </li>
        </ul>
        <p class="paragraph">
          We may share your personal information in the following situations:
        </p>
        <ul>
          <li>
            <span>With Service Providers:</span> We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You.
          </li>
          <li>
            <span>For Business transfers:</span> We may share or transfer Your
            personal information in connection with, or during negotiations of,
            any merger, sale of Company assets, financing, or acquisition of all
            or a portion of our business to another company.
          </li>
          <li>
            <span>With Affiliates:</span> We may share Your information with Our
            affiliates, in which case we will require those affiliates to honor
            this Privacy Policy. Affiliates include Our parent company and any
            other subsidiaries, joint venture partners or other companies that
            We control or that are under common control with Us.
          </li>
          <li>
            <span>With Business partners:</span> We may share Your information
            with Our business partners to offer You certain products, services
            or promotions.
          </li>
          <li>
            <span>With other users:</span> when You share personal information
            or otherwise interact in the public areas with other users, such
            information may be viewed by all users and may be publicly
            distributed outside. If You interact with other users or register
            through a Third-Party Social Media Service, Your contacts on the
            Third-Party Social Media Service may see Your name, profile,
            pictures and description of Your activity. Similarly, other users
            will be able to view descriptions of Your activity, communicate with
            You and view Your profile.
          </li>
        </ul>
        <h4 class="subtitle">Retention of Your Personal Data</h4>
        <p class="paragraph">
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>

        <p class="paragraph">
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>

        <h4 class="subtitle">Transfer of Your Personal Data</h4>
        <p class="paragraph">
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to â€” and maintained on â€” computers located
          outside of Your state, province, country or other governmental
          jurisdiction where the data protection laws may differ than those from
          Your jurisdiction.
        </p>

        <p class="paragraph">
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>

        <p class="paragraph">
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>

        <h4 class="subtitle">Disclosure of Your Personal Data</h4>
        <h5 class="metatitle">Business Transactions</h5>
        <p class="paragraph">
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>

        <h5 class="metatitle">Law enforcement</h5>
        <p class="paragraph">
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>

        <h5 class="metatitle">Other legal requirements</h5>
        <p class="paragraph">
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
          <li>Security of Your Personal Data</li>
        </ul>
        <p class="paragraph">
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>

        <h3 class="title">GDPR Privacy</h3>
        <h4 class="subtitle">
          Legal Basis for Processing Personal Data under GDPR
        </h4>
        <p class="paragraph">
          We may process Personal Data under the following conditions:
        </p>
        <ul>
          <li>
            <span>Consent:</span> You have given Your consent for processing
            Personal Data for one or more specific purposes.
          </li>
          <li>
            <span>Performance of a contract:</span> Provision of Personal Data
            is necessary for the performance of an agreement with You and/or for
            any pre-contractual obligations thereof.
          </li>
          <li>
            <span>Legal obligations:</span> Processing Personal Data is
            necessary for compliance with a legal obligation to which the
            Company is subject.
          </li>
          <li>
            <span>Vital interests:</span> Processing Personal Data is necessary
            in order to protect Your vital interests or of another natural
            person.
          </li>
          <li>
            <span>Public interests:</span> Processing Personal Data is related
            to a task that is carried out in the public interest or in the
            exercise of official authority vested in the Company.
          </li>
          <li>
            <span>Legitimate interests:</span> Processing Personal Data is
            necessary for the purposes of the legitimate interests pursued by
            the Company.
          </li>
        </ul>
        <p class="paragraph">
          In any case, the Company will gladly help to clarify the specific
          legal basis that applies to the processing, and in particular whether
          the provision of Personal Data is a statutory or contractual
          requirement, or a requirement necessary to enter into a contract.
        </p>

        <h4 class="subtitle">Your Rights under the GDPR</h4>
        <p class="paragraph">
          The Company undertakes to respect the confidentiality of Your Personal
          Data and to guarantee You can exercise Your rights.
        </p>

        <p class="paragraph">
          You have the right under this Privacy Policy, and by law if You are
          within the EU, to:
        </p>
        <ul>
          <li>
            <span>Request access to Your Personal Data</span>. The right to
            access, update or delete the information We have on You. Whenever
            made possible, you can access, update or request deletion of Your
            Personal Data directly within Your account settings section. If you
            are unable to perform these actions yourself, please contact Us to
            assist You. This also enables You to receive a copy of the Personal
            Data We hold about You.
          </li>
          <li>
            <span
              >Request correction of the Personal Data that We hold about
              You</span
            >. You have the right to to have any incomplete or inaccurate
            information We hold about You corrected.
          </li>
          <li>
            <span>Object to processing of Your Personal Data</span>. This right
            exists where We are relying on a legitimate interest as the legal
            basis for Our processing and there is something about Your
            particular situation, which makes You want to object to our
            processing of Your Personal Data on this ground. You also have the
            right to object where We are processing Your Personal Data for
            direct marketing purposes.
          </li>
          <li>
            <span>Request erasure of Your Personal Data</span>. You have the
            right to ask Us to delete or remove Personal Data when there is no
            good reason for Us to continue processing it.
          </li>
          <li>
            <span>Request the transfer of Your Personal Data</span>. We will
            provide to You, or to a third-party You have chosen, Your Personal
            Data in a structured, commonly used, machine-readable format. Please
            note that this right only applies to automated information which You
            initially provided consent for Us to use or where We used the
            information to perform a contract with You.
          </li>
          <li>
            <span>Withdraw Your consent</span>. You have the right to withdraw
            Your consent on using your Personal Data. If You withdraw Your
            consent, We may not be able to provide You with access to certain
            specific functionalities of the Service.
          </li>
        </ul>
        <h4 class="subtitle">Exercising of Your GDPR Data Protection Rights</h4>
        <p class="paragraph">
          You may exercise Your rights of access, rectification, cancellation
          and opposition by contacting Us. Please note that we may ask You to
          verify Your identity before responding to such requests. If You make a
          request, We will try our best to respond to You as soon as possible.
        </p>

        <p class="paragraph">
          You have the right to complain to a Data Protection Authority about
          Our collection and use of Your Personal Data. For more information, if
          You are in the European Economic Area (EEA), please contact Your local
          data protection authority in the EEA.
        </p>

        <h3 class="title">CCPA Privacy</h3>
        <h4 class="subtitle">Your Rights under the CCPA</h4>
        <p class="paragraph">
          Under this Privacy Policy, and by law if You are a resident of
          California, You have the following rights:
        </p>
        <ul>
          <li>
            <span>The right to notice</span>. You must be properly notified
            which categories of Personal Data are being collected and the
            purposes for which the Personal Data is being used.
          </li>
          <li>
            <span>The right to access / the right to request</span>. The CCPA
            permits You to request and obtain from the Company information
            regarding the disclosure of Your Personal Data that has been
            collected in the past 12 months by the Company or its subsidiaries
            to a third-party for the third party's direct marketing purposes.
          </li>
          <li>
            <span>The right to say no to the sale of Personal Data</span>. You
            also have the right to ask the Company not to sell Your Personal
            Data to third parties. You can submit such a request by visiting our
            "Do Not Sell My Personal Information" section or web page.
          </li>
          <li>
            <span>The right to know about Your Personal Data</span>. You have
            the right to request and obtain from the Company information
            regarding the disclosure of the following:
            <ul>
              <li>The categories of Personal Data collected</li>
              <li>The sources from which the Personal Data was collected</li>
              <li>
                The business or commercial purpose for collecting or selling the
                Personal Data
              </li>
              <li>
                Categories of third parties with whom We share Personal Data
              </li>
              <li>
                The specific pieces of Personal Data we collected about You
              </li>
            </ul>
          </li>
          <li>
            <span>The right to delete Personal Data</span>. You also have the
            right to request the deletion of Your Personal Data that have been
            collected in the past 12 months.
          </li>
          <li>
            <span>The right not to be discriminated against</span>. You have the
            right not to be discriminated against for exercising any of Your
            Consumer's rights, including by:
            <ul>
              <li>Denying goods or services to You</li>
              <li>
                Charging different prices or rates for goods or services,
                including the use of discounts or other benefits or imposing
                penalties
              </li>
              <li>
                Providing a different level or quality of goods or services to
                You
              </li>
              <li>
                Suggesting that You will receive a different price or rate for
                goods or services or a different level or quality of goods or
                services.
              </li>
            </ul>
          </li>
        </ul>
        <h4 class="subtitle">Exercising Your CCPA Data Protection Rights</h4>
        <p class="paragraph">
          In order to exercise any of Your rights under the CCPA, and if you are
          a California resident, You can email or call us or visit our "Do Not
          Sell My Personal Information" section or web page.
        </p>

        <p class="paragraph">
          The Company will disclose and deliver the required information free of
          charge within 45 days of receiving Your verifiable request. The time
          period to provide the required information may be extended once by an
          additional 45 days when reasonable necessary and with prior notice.
        </p>

        <h4 class="subtitle">Do Not Sell My Personal Information</h4>
        <p class="paragraph">
          We do not sell personal information. However, the Service Providers we
          partner with (for example, our advertising partners) may use
          technology on the Service that "sells" personal information as defined
          by the CCPA law.
        </p>

        <p class="paragraph">
          If you wish to opt out of the use of your personal information for
          interest-based advertising purposes and these potential sales as
          defined under CCPA law, you may do so by following the instructions
          below.
        </p>

        <p class="paragraph">
          Please note that any opt out is specific to the browser You use. You
          may need to opt out on every browser that you use.
        </p>

        <h5 class="metatitle">Website</h5>
        <p class="paragraph">
          You can opt out of receiving ads that are personalized as served by
          our Service Providers by following our instructions presented on the
          Service:
        </p>
        <ul>
          <li>From Our "Cookie Consent" notice banner</li>
          <li>Or from Our "CCPA Opt-out" notice banner</li>
          <li>
            Or from Our "Do Not Sell My Personal Information" notice banner
          </li>
          <li>Or from Our "Do Not Sell My Personal Information" link</li>
        </ul>
        <p class="paragraph">
          The opt out will place a cookie on Your computer that is unique to the
          browser You use to opt out. If you change browsers or delete the
          cookies saved by your browser, you will need to opt out again.
        </p>

        <h5 class="metatitle">Mobile Devices</h5>
        <p class="paragraph">
          Your mobile device may give you the ability to opt out of the use of
          information about the apps you use in order to serve you ads that are
          targeted to your interests:
        </p>
        <ul>
          <li>
            "Opt out of Interest-Based Ads" or "Opt out of Ads Personalization"
            on Android devices
          </li>
          <li>"Limit Ad Tracking" on iOS devices</li>
        </ul>
        <p class="paragraph">
          You can also stop the collection of location information from Your
          mobile device by changing the preferences on your mobile device.
        </p>

        <h3 class="title">
          "Do Not Track" Policy as Required by California Online Privacy
          Protection Act (CalOPPA)
        </h3>
        <p class="paragraph">
          Our Service does not respond to Do Not Track signals.
        </p>

        <p class="paragraph">
          However, some third party websites do keep track of Your browsing
          activities. If You are visiting such websites, You can set Your
          preferences in Your web browser to inform websites that You do not
          want to be tracked. You can enable or disable DNT by visiting the
          preferences or settings page of Your web browser.
        </p>

        <h3 class="title">
          Your California Privacy Rights (California's Shine the Light law)
        </h3>
        <p class="paragraph">
          Under California Civil Code Section 1798 (California's Shine the Light
          law), California residents with an established business relationship
          with us can request information once a year about sharing their
          Personal Data with third parties for the third parties' direct
          marketing purposes.
        </p>

        <p class="paragraph">
          If you'd like to request more information under the California Shine
          the Light law, and if you are a California resident, You can contact
          Us using the contact information provided below.
        </p>

        <h3 class="title">
          California Privacy Rights for Minor Users (California Business and
          Professions Code Section 22581)
        </h3>
        <p class="paragraph">
          California Business and Professions Code section 22581 allow
          California residents under the age of 18 who are registered users of
          online sites, services or applications to request and obtain removal
          of content or information they have publicly posted.
        </p>

        <p class="paragraph">
          To request removal of such data, and if you are a California resident,
          You can contact Us using the contact information provided below, and
          include the email address associated with Your account.
        </p>

        <p class="paragraph">
          Be aware that Your request does not guarantee complete or
          comprehensive removal of content or information posted online and that
          the law may not permit or require removal in certain circumstances.
        </p>

        <h3 class="title">Links to Other Websites</h3>
        <p class="paragraph">
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>

        <p class="paragraph">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>

        <h3 class="subtitle">Changes to this Privacy Policy</h3>
        <p class="paragraph">
          We may update our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>

        <p class="paragraph">
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>

        <p class="paragraph">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>

        <h3 class="title">Contact Us</h3>
        <p class="paragraph">
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul>
          <li>By email: legal@storyline.co</li>
        </ul>
      </div>
    </div>
  </SubPageWrap>
</template>

<script>
import SubPageWrap from '@/components/layout/SubPageWrap.vue'
export default {
  metaInfo: {
    title: 'Privacy Policy'
  },
  components: {
    SubPageWrap
  }
}
</script>

<style lang="scss" scoped>
.pp-wrap {
  padding: 100px 0;
  .content-squeeze {
    flex-direction: column;
  }
  .title {
    font-family: 'sans-bold';
    font-size: 2em;
    margin-top: 40px;
  }
  .subtitle {
    font-family: 'sans-bold';
    font-size: 1.5em;
    margin-top: 20px;
  }
  .metatitle {
    font-family: 'sans-bold';
    font-size: 1.17em;
    margin-top: 20px;
  }
  .paragraph {
    margin-top: 20px;
  }
  ul {
    margin-top: 20px;
    padding: 0 0 0 30px;
    li {
      margin-top: 5px;
      span {
        font-family: 'sans-bold';
      }
      ul {
        margin-top: 5px;
        li {
          margin-top: 5px;
          list-style: circle;
        }
      }
    }
  }
}
</style>
