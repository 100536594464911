<template>

  <div class="app-link-wrap">
    <a href="https://apps.apple.com/us/app/storyline-share-memories/id1560957153" target="_blank" class="app-link" :class="{ 'has-shadow': hasShadow }">
      <svg
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24.069"
        height="29.375"
        viewBox="0 0 24.069 29.375"
      >
        <g transform="translate(-3.135 -1.13)">
          <g data-name="Group 257" transform="translate(3.135 1.13)">
            <path
              data-name="Path 92"
              d="M23.265,16.759a6.606,6.606,0,0,1,3.215-5.6,6.909,6.909,0,0,0-5.431-2.916c-2.292-.233-4.509,1.363-5.665,1.363-1.2,0-2.981-1.341-4.921-1.3a7.212,7.212,0,0,0-6.08,3.673c-2.628,4.51-.667,11.159,1.843,14.814C7.48,28.579,8.97,30.581,10.891,30.5c1.882-.077,2.589-1.186,4.863-1.186,2.254,0,2.92,1.186,4.881,1.146,2.02-.038,3.295-1.787,4.51-3.6A14.433,14.433,0,0,0,27.2,22.689a6.454,6.454,0,0,1-3.941-5.93M19.54,5.835a6.5,6.5,0,0,0,1.509-4.7,6.785,6.785,0,0,0-4.372,2.235,6.1,6.1,0,0,0-1.548,4.529,5.524,5.524,0,0,0,4.411-2.06"
              transform="translate(-3.135 -1.13)"
              fill="#000"
            />
          </g>
        </g>
      </svg>
      <span>Download</span>
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.storylinememorials.v2"
      target="_blank"
      class="app-link"
      :class="{ 'has-shadow': hasShadow }"
    >
      <svg
        class="icon"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 469.847 469.847"
        style="enable-background:new 0 0 469.847 469.847;"
        xml:space="preserve"
      >
        <path
          style="fill:#2196F3;"
          d="M270.336,234.965L34.39,462.165c-11.049-7.551-17.609-20.111-17.493-33.493V41.258
      C16.781,27.876,23.341,15.316,34.39,7.765L270.336,234.965z"
        />
        <path
          style="fill:#4CAF50;"
          d="M352.896,155.605l-82.56,79.36L34.39,7.765c0.889-0.689,1.815-1.33,2.773-1.92
      c12.47-7.558,28.046-7.802,40.747-0.64L352.896,155.605z"
        />
        <path
          style="fill:#FFC107;"
          d="M452.95,234.965c0.154,15.107-8.055,29.061-21.333,36.267l-78.72,43.093l-82.56-79.36l82.56-79.36
      l78.72,43.093C444.895,205.904,453.104,219.858,452.95,234.965z"
        />
        <path
          style="fill:#F44336;"
          d="M352.896,314.325L77.91,464.725c-12.729,7.051-28.246,6.807-40.747-0.64
      c-0.958-0.59-1.884-1.231-2.773-1.92l235.947-227.2L352.896,314.325z"
        />
      </svg>
      <span>Download</span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    hasShadow: Boolean
  }
}
</script>

<style lang="scss" scoped>
.app-link-wrap {
  display: flex;
  margin-top: 40px;
  @media screen and (max-width: 650px) {
    justify-content: center;
  }
}
.app-link {
  align-items: center;
  background: var(--white);
  border-radius: 5px;
  display: flex;
  padding: 15px 32px;
  text-decoration: none;
  @media screen and (max-width: 1440px) {
    padding: 12px 24px;
  }
  @media screen and (max-width: 767px) {
    padding: 5px 10px;
  }
  &.has-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  &:first-child {
    margin-right: 20px;
  }
  .icon {
    margin-right: 10px;
    width: 25px;
    @media screen and (max-width: 767px) {
      margin-right: 5px;
      width: 15px;
    }
  }
  span {
    color: var(--black);
    font-family: 'sans-bold';
    font-size: 2rem;
    text-decoration: none;
    @media screen and (max-width: 1440px) {
      font-size: 1.6rem;
    }
  }
}
</style>
